import { Grid2, SxProps, ToggleButton, Typography, Theme, Button } from '@mui/material';
import { Round } from './types/poolx';
import { CouponInfo, UserCoupon } from './types/usercoupon';
import React, { useEffect } from 'react';
import { RSystems } from './types/rsystems';
import { getFriendlyName } from './api/PoolxApi';

const SystemCoupon = ({ round, system }: { round: Round, system: string }) => {

    let [coupon, setCoupon] = React.useState<UserCoupon>();
    let [valid, setValid] = React.useState(false);
    let [couponInfo, setCouponInfo] = React.useState(new CouponInfo(0, 0, 0));
    let [update, setUpdate] = React.useState(0);

    useEffect(() => {
        if (round && (coupon === undefined || coupon.roundId !== round.id || coupon.system !== system)) {
            let newCoupon: UserCoupon = { roundId: round.id, couponFixtures: [], system: system }

            if (round?.fixtures) {
                for (let index = 0; index < round?.fixtures.length; index++) {
                    newCoupon.couponFixtures.push({ gameIndex: index, "1": false, "X": false, "2": false, isMathematical: false });
                }
            }
            setCoupon(newCoupon);
        }
    }, [coupon, round, system]);


    if (round === undefined || coupon === undefined) {
        return (<></>);
    }

    if (round.id !== coupon.roundId) {
        return (<></>);
    }

    // Regular expression to capture the first three numbers after underscores
    const regex = /^R_(\d+)_(\d+)_(\d+)/;

    // Match the string against the regex
    const match = system.match(regex);

    if (!match) {
        return <></>;
    }

    const whole = Number.parseInt(match[1]);
    const half = Number.parseInt(match[2]);
    const rowsInSystem = Number.parseInt(match[3]);

    let button: SxProps<Theme> = {
        padding: "0px 5px",
        textAlign: "center",
        height: "40px",
        minWidth: "40px",
        maxWidth: "40px",
        marginRight: "10px",
    }

    let signText: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "18px",
    }

    let teams: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "500",
    }

    let gameNr: SxProps<Theme> = {
        width: "20px",
        marginRight: "5px"
    }

    const styleRow: SxProps<Theme> = {
        padding: "10px",
        borderBottom: "1px solid"
    };

    function onButtonChange(gameIndex: number, selection: string): void {

        setCoupon((prev) => {

            let newCoupon = prev as UserCoupon;

            switch (selection) {
                case "1":
                    newCoupon.couponFixtures[gameIndex]["1"] = !(prev as UserCoupon).couponFixtures[gameIndex]["1"];
                    break;
                case "X":
                    newCoupon.couponFixtures[gameIndex]["X"] = !(prev as UserCoupon).couponFixtures[gameIndex]["X"];
                    break;
                case "2":
                    newCoupon.couponFixtures[gameIndex]["2"] = !(prev as UserCoupon).couponFixtures[gameIndex]["2"];
                    break;
                case "M":
                    newCoupon.couponFixtures[gameIndex].isMathematical = !(prev as UserCoupon).couponFixtures[gameIndex].isMathematical;
                    break;
            }

            return newCoupon;

        });
        setValid(validateCoupon(coupon as UserCoupon));
        setUpdate((old) => old + 1);
    }

    class SignNode {
        // Declare properties
        public level: number;
        private sign: string;
        private parents: string;
        public row: string = "";
        public children: SignNode[] = [];

        // Constructor with two parameters
        constructor(level: number, sign: string, parents: string) {
            this.level = level;
            this.sign = sign;
            this.parents = parents;
            if (level === 1)
                this.row = sign + ",";
            else if (level > 1)
                this.row = parents + sign + ",";
        }
    }



    function addSign(signNode: SignNode, coupon: UserCoupon) {
        var nextLevel = signNode.level + 1;

        if (signNode.level < coupon.couponFixtures.length) {
            let signs: string[] = [];
            let couponFixture = coupon.couponFixtures[signNode.level];

            let numSel = ((couponFixture["1"] ? 1 : 0) + (couponFixture["X"] ? 1 : 0) + (couponFixture["2"] ? 1 : 0))

            if (couponFixture.isMathematical || numSel === 1) {
                if (couponFixture["1"]) {
                    signs.push("1");
                }
                if (couponFixture["X"]) {
                    signs.push("X");
                }
                if (couponFixture["2"]) {
                    signs.push("2");
                }
            }
            else {
                signs.push("R" + numSel.toString() + "_" + (couponFixture["1"] ? 1 : 0).toString() + (couponFixture["X"] ? 1 : 0).toString() + (couponFixture["2"] ? 1 : 0).toString());
            }

            for (let i = 0; i < signs.length; i++) {
                let sign = signs[i];
                var node = new SignNode(nextLevel, sign, signNode.row);
                signNode.children.push(node);
                addSign(node, coupon);
            }
        }

        return;
    }

    function getRows(node: SignNode, rows: string[]) {

        for (let i = 0; i < node.children.length; i++) {
            let child = node.children[i];
            if (child.children.length === 0) {

                let systems = new RSystems();
                const propertyName: keyof RSystems = system as keyof RSystems;
                let systemSigns = systems[propertyName];

                for (let j = 0; j < systemSigns.length; j++) {

                    let key = systemSigns[j];
                    let rawRow = child.row.substring(0, child.row.length - 1);

                    if (whole > 0) {
                        key = key.replaceAll('1', 'X');
                        key = key.replaceAll('0', '1');
                        let wholeKey = key.slice(0, whole);
                        let index = 0; // Tracks the position in the replacement array
                        rawRow = rawRow.replace(/R3_\d{3}/g, () => {
                            // Use the value from the replacement array or keep "R3" if replacements are exhausted
                            return wholeKey[index++] || "R3";
                        });
                    }

                    if (half > 0) {

                        key = key.replaceAll('0', 'a');
                        key = key.replaceAll('1', 'b');

                        let halfKey = key.slice(whole);
                        let index = 0; // Tracks the position in the replacement array
                        rawRow = rawRow.replace(/R2_\d{3}/g, (m) => {

                            let thisKey = halfKey;

                            if (m.indexOf("110") > -1) {
                                thisKey = thisKey.replaceAll('a', '1');
                                thisKey = thisKey.replaceAll('b', 'X');
                            }
                            if (m.indexOf("101") > -1) {
                                thisKey = thisKey.replaceAll('a', '1');
                                thisKey = thisKey.replaceAll('b', '2');
                            }
                            if (m.indexOf("011") > -1) {
                                thisKey = thisKey.replaceAll('a', 'X');
                                thisKey = thisKey.replaceAll('b', '2');
                            }
                            
                            // Use the value from the replacement array or keep "R3" if replacements are exhausted
                            return thisKey[index++] || "R2";
                        });
                    }

                    rows.push("E," + rawRow);

                }
            }
            getRows(child, rows);
        }

        return rows;
    }

    const handleExport = () => {

        let root = new SignNode(0, "", "");
        addSign(root, coupon as UserCoupon);

        let allRows = getRows(root, []);

        const textContent = (coupon as UserCoupon).roundId + "\n" + allRows.join('\n');
        const fileName = getFriendlyName(round.name) + "_" + round.id + "_" + round.openTo + ".txt";

        // Create a blob with the text content
        const blob = new Blob([textContent], { type: "text/plain" });

        // Create an anchor element and simulate a click to download the file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Simulate the click and clean up
        link.click();
        URL.revokeObjectURL(link.href);
    };

    function validateCoupon(coupon: UserCoupon): boolean {

        if (!coupon) {
            return false;
        }

        let valid = true;
        let nrRows = rowsInSystem;
        let halfCover = 0;
        let wholeCover = 0;

        for (let i = 0; i < coupon.couponFixtures.length; i++) {

            let couponFixture = coupon.couponFixtures[i];

            if (!couponFixture["1"] && !couponFixture["X"] && !couponFixture["2"]) {
                valid = false;
            }

            let numSel = ((couponFixture["1"] ? 1 : 0) + (couponFixture["X"] ? 1 : 0) + (couponFixture["2"] ? 1 : 0))
            if (numSel > 0 && couponFixture.isMathematical) {
                nrRows *= numSel;
            }
            if (numSel === 2 && !couponFixture.isMathematical) {
                halfCover++;
            }
            if (numSel === 3 && !couponFixture.isMathematical) {
                wholeCover++;
            }
        }

        if (halfCover !== half || wholeCover !== whole) {
            valid = false;
        }

        setCouponInfo(new CouponInfo(nrRows, halfCover, wholeCover));


        return valid;
    }


    return (
        <Grid2 container direction="column" sx={{ alignContent: "center", marginTop: "30px", marginBottom: "20px" }}>

            <Grid2 container direction="column"
                sx={{ minWidth: "340px", maxWidth: "700px", margin: "0px" }}>
                <Typography sx={signText}>{"Rader: " + couponInfo.numRows}</Typography>
                <Typography sx={signText}>{"Helgarderingar: " + couponInfo.numWholeCover + " (" + whole + ")"}</Typography>
                <Typography sx={signText}>{"Halvgarderingar: " + couponInfo.numHalfCover + " (" + half + ")"}</Typography>
                <Typography visibility="hidden" sx={signText}>{update}</Typography>
                {round.fixtures.map((fixture, i) => {

                    return (
                        <Grid2 key={"row_header" + i} sx={styleRow}>
                            <Grid2 container key={"row_" + i}
                                sx={{ flexDirection: "var(--flex-direction)", flexWrap: "nowrap" }}
                                alignItems="stretch">
                                <Grid2 component="div">
                                    <Typography
                                        sx={{ ...teams, ...gameNr } as SxProps<Theme>}>{i + 1}</Typography>
                                </Grid2>
                                <Grid2 component="div" sx={{ flexGrow: 1, paddingRight: "5px", width:"200px" }}>
                                    <Grid2 container>
                                        <Typography sx={teams}>{fixture.homeTeam.name}</Typography>
                                    </Grid2>
                                    <Grid2 container>
                                        <Typography sx={teams}>{fixture.awayTeam.name}</Typography>
                                    </Grid2>

                                </Grid2>

                                <Grid2 container sx={{ flexGrow: 1, paddingRight: "5px", minWidth:"130px" }}>
                                    <Grid2 container sx={{ width:"40px"}}>
                                        <Typography sx={teams}>{(fixture.stakePercentages[1] * 100).toFixed(0) + "%"}</Typography>
                                    </Grid2>
                                    <Grid2 container sx={{ width:"40px"}}>
                                        <Typography sx={teams}>{(fixture.stakePercentages["X"] * 100).toFixed(0) + "%"}</Typography>
                                    </Grid2>
                                    <Grid2 container sx={{ width:"40px"}}>
                                        <Typography sx={teams}>{(fixture.stakePercentages[2] * 100).toFixed(0) + "%"}</Typography>
                                    </Grid2>
                                </Grid2>

                                <Grid2 component="div" sx={{ minHeight: '100%' }}>
                                    <Grid2 container alignItems="center" sx={{
                                        minHeight: '100%',
                                        flexDirection: "var(--flex-direction)",
                                        flexWrap: "nowrap"
                                    }}>
                                        <ToggleButton name="1"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["1"]}
                                            value="1" sx={button} onChange={() => onButtonChange(i, "1")}>
                                            <Typography sx={signText}>1</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="X"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["X"]}
                                            value="X" sx={button} onChange={() => onButtonChange(i, "X")}>
                                            <Typography sx={signText}>X</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="2"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["2"]}
                                            value="2" sx={button} onChange={() => onButtonChange(i, "2")}>
                                            <Typography sx={signText}>2</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="M"
                                            selected={(coupon as UserCoupon).couponFixtures[i].isMathematical}
                                            value="M" sx={button} onChange={() => onButtonChange(i, "M")}>
                                            <Typography sx={signText}>M</Typography>
                                        </ToggleButton>
                                    </Grid2>
                                </Grid2>

                            </Grid2>
                        </Grid2>
                    )
                }

                )}
            </Grid2>

            <Button disabled={!valid} onClick={handleExport} variant="contained" sx={{ margin: "20px" }}>Skapa fil</Button>

        </Grid2>
    );
}
export default SystemCoupon;