import React, { useEffect } from "react";
import { Round } from "./types/poolx";
import { Container, FormControl, Grid2, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from "@mui/material";
import { getFriendlyName, getPoolXRounds } from "./api/PoolxApi";
import SystemCoupon from "./SystemCoupon";
import { RSystems } from "./types/rsystems";
import { format } from "date-fns";

export default function CouponView() {

    const [poolXRounds, setPoolXRounds] = React.useState<Round[]>([]);
    const [selectedRoundId, setSelectedRoundId] = React.useState<number>(0);
    const [selectedSystem, setSelectedSystem] = React.useState<string>("");

    useEffect(() => {
        if (poolXRounds.length === 0) {
            getPoolXRounds(setPoolXRounds);
        }

    }, [poolXRounds]);

    const handleChange = (event: SelectChangeEvent<number>) => {
        setSelectedRoundId(event.target.value as number)
    }

    const handleChangeSystem = (event: SelectChangeEvent<unknown>) => {
        setSelectedSystem(event.target.value as string)
    }

    let selectedRound = poolXRounds.find(r => r.id === selectedRoundId);

    

    return (
        <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
            <Grid2 container spacing={3} id="coupons" direction="column">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                    <FormControl>
                        <InputLabel id="select-label">Välj omgång</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            label="Omgång"
                            value={selectedRoundId}
                            onChange={(event) => handleChange(event)}
                        >

                            <MenuItem key={"round" + 0} value={0}>Välj</MenuItem>
                            {
                                poolXRounds.map((round, index) =>
                                    <MenuItem key={"round" + index} value={round.id}>{getFriendlyName(round.name) + " - " + format(new Date(round.openTo), 'yyyy-MM-dd HH:mm')}</MenuItem>
                                )

                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "20px" }}>
                        <InputLabel id="select-label">Välj system</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            label="Omgång"
                            value={selectedSystem}
                            onChange={(event) => handleChangeSystem(event)}
                        >
                            <MenuItem key={"system"} value={""}>Välj system</MenuItem>
                            {
                                Object.keys(new RSystems()).map((round, index) =>
                                    <MenuItem key={"system" + index} value={round}>{round}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Paper>

                {selectedRound &&
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                        <SystemCoupon round={selectedRound} system={selectedSystem}/>
                    </Paper>
                }

            </Grid2>
        </Container>
    );

}