import React from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  createTheme,
  Grid2,
  Container,
} from "@mui/material";
import CouponView from "./CouponView";
import { EgnaraderLogo } from "./images/logo";

// Create a custom theme
const theme = createTheme();


export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        {/* AppBar */}
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "#BBBBBB" }}
        >
          <Toolbar>
            {EgnaraderLogo()}
            <Typography variant="h6" noWrap component="div">

            </Typography>
          </Toolbar>

        </AppBar>
        {/* Main Content */}
        <Toolbar />
        <Container maxWidth="lg">
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
          >
            <Toolbar />
            <Grid2 container sx={{ maxWidth: "500px" }}>
              <Typography>Välkommen, här kan du göra egna rader</Typography>
            </Grid2>
            <CouponView />
          </Box>
        </Container>

      </div>
    </ThemeProvider>
  );
};


