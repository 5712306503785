
export class RSystems {
    public R_0_7_16: string[] = ["0000000",
        "0000111",
        "0011001",
        "0011110",
        "0101011",
        "0101100",
        "0110010",
        "0110101",
        "1001010",
        "1001101",
        "1010011",
        "1010100",
        "1100001",
        "1100110",
        "1111000",
        "1111111"
    ];

    public R_0_11_16: string[] = ["00000000000",
        "00000111111",
        "00111000111",
        "00111111000",
        "11001001011",
        "11001110100",
        "11110001100",
        "11110110011",
        "01010010101",
        "01010101010",
        "01101010010",
        "01101101101",
        "10011011110",
        "10011100001",
        "10100011001",
        "10100100110"
    ];

    public R_2_5_12: string[] = ["2211110",
        "0110001",
        "0110110",
        "2000101",
        "2211001",
        "1200100",
        "1200011",
        "0101010",
        "2000010",
        "1011111",
        "1011000",
        "0101101"
    ];

    public R_3_3_24: string[] = ["010111",
        "022001",
        "202100",
        "202011",
        "211110",
        "121000",
        "022110",
        "112010",
        "121111",
        "100001",
        "202111",
        "010100",
        "211001",
        "001010",
        "112101",
        "220101",
        "202000",
        "121100",
        "100110",
        "010000",
        "010011",
        "121011",
        "001101",
        "220010"
    ];

    public R_4_0_9: string[] = ["0010",
        "0101",
        "0222",
        "1200",
        "1021",
        "1112",
        "2120",
        "2211",
        "2002"
    ];

    public R_4_4_24: string[] = ["00011010",
        "02100110",
        "20101111",
        "20100000",
        "21021001",
        "11120101",
        "02101001",
        "22110011",
        "11121010",
        "10210110",
        "22201010",
        "01211111",
        "21020110",
        "11000011",
        "22111100",
        "00221100",
        "22200101",
        "12021111",
        "10211001",
        "00010101",
        "01210000",
        "12020000",
        "11001100",
        "00220011"
    ];

    public R_4_4_144: string[] = ["01200110",
        "22211110",
        "11111101",
        "00101010",
        "02111001",
        "02120100",
        "21121010",
        "01010100",
        "12221000",
        "20011010",
        "02021010",
        "11201100",
        "10021011",
        "22120101",
        "00000001",
        "21020001",
        "02010110",
        "20210001",
        "01021101",
        "02101101",
        "10201110",
        "20021110",
        "12000101",
        "00200100",
        "00021111",
        "10110101",
        "22121111",
        "10001000",
        "12221101",
        "01211010",
        "10111111",
        "02001110",
        "21201000",
        "01100101",
        "21100011",
        "01220011",
        "00201011",
        "12011100",
        "01120010",
        "20200101",
        "21221011",
        "21120000",
        "11000000",
        "22000100",
        "00120110",
        "12210100",
        "11221111",
        "22021001",
        "12121110",
        "10200011",
        "11010011",
        "22110010",
        "01110001",
        "21000111",
        "10001101",
        "21101110",
        "10210110",
        "22001011",
        "20110100",
        "11011110",
        "01011011",
        "10011001",
        "01111100",
        "22210011",
        "20111011",
        "21220100",
        "02211111",
        "21211001",
        "02020000",
        "10221010",
        "02210101",
        "00100000",
        "20220010",
        "10121100",
        "10220000",
        "20000110",
        "00010111",
        "00010010",
        "00111110",
        "12020010",
        "21201101",
        "22011101",
        "21010101",
        "12120011",
        "21000010",
        "20211100",
        "10120001",
        "10100111",
        "02101000",
        "00221001",
        "21011111",
        "01001001",
        "20201111",
        "01101111",
        "01221110",
        "20010000",
        "01021000",
        "20121101",
        "02200010",
        "02200111",
        "00001100",
        "00020101",
        "20101001",
        "22201010",
        "01210000",
        "22220110",
        "22110111",
        "02000011",
        "12111010",
        "02221100",
        "11200001",
        "11121001",
        "11210111",
        "11020110",
        "22101100",
        "20020011",
        "20220111",
        "11220101",
        "21021100",
        "00110011",
        "11210010",
        "22200000",
        "21110110",
        "12020111",
        "00211101",
        "02220001",
        "00211000",
        "10020100",
        "12201001",
        "12110000",
        "12100110",
        "10100010",
        "12010001",
        "11101011",
        "20121000",
        "11001010",
        "12211011",
        "11111000",
        "12001111",
        "22100001",
        "01120111",
        "22011000",
        "02121011",
        "11100100"
    ];


    public R_4_5_48: string[] = ["121210111",
        "222200010",
        "210211101",
        "102210000",
        "201000100",
        "012111010",
        "022210001",
        "120211010",
        "220100101",
        "220111011",
        "102111101",
        "202001001",
        "202010111",
        "120200100",
        "011201000",
        "000000010",
        "201011010",
        "100001111",
        "102100011",
        "111100010",
        "011210110",
        "112011011",
        "001211011",
        "200101000",
        "021011101",
        "000011100",
        "111111100",
        "022201111",
        "021101110",
        "121201001",
        "001200101",
        "210200011",
        "010110111",
        "122010110",
        "112000101",
        "012100100",
        "021000011",
        "102201110",
        "222211100",
        "021110000",
        "100010001",
        "211110001",
        "210001110",
        "010101001",
        "211101111",
        "210010000",
        "200110110",
        "122001000"
    ];

    public R_4_6_24: string[] = ["0000000000",
        "0000011110",
        "2011010101",
        "2021001011",
        "1122011000",
        "1112000110",
        "1202001101",
        "1202010011",
        "2211011000",
        "2221000110",
        "0120010101",
        "0110001011",
        "0000111001",
        "0000100111",
        "0011101100",
        "0021110010",
        "1121100001",
        "1111111111",
        "1202110100",
        "1202101010",
        "2212100001",
        "2222111111",
        "2120101100",
        "2110110010"
    ];

    public R_4_6_72: string[] = ["1012001011",
        "0120100110",
        "1021111000",
        "1120010101",
        "2111011001",
        "0201111000",
        "2111110100",
        "1012011110",
        "2000101010",
        "2111101010",
        "2222110100",
        "2222000111",
        "0210010101",
        "0210001011",
        "0210011110",
        "2000011001",
        "2222101010",
        "2000001100",
        "0120111000",
        "0021010101",
        "1210110011",
        "1102101101",
        "1021101101",
        "0021000000",
        "2111010010",
        "2222111111",
        "0102000000",
        "2222100001",
        "1012000000",
        "0120101101",
        "2000000111",
        "0201100110",
        "1102110011",
        "0012101101",
        "1021100110",
        "2222001100",
        "2000110100",
        "1120000000",
        "1012010101",
        "2000010010",
        "0021001011",
        "0120110011",
        "0021011110",
        "0201101101",
        "0012111000",
        "1201001011",
        "1201010101",
        "0210000000",
        "1201000000",
        "2000111111",
        "0201110011",
        "0102011110",
        "2111000111",
        "1102100110",
        "1021110011",
        "2111100001",
        "2222010010",
        "2111111111",
        "0012100110",
        "2222011001",
        "1102111000",
        "2111001100",
        "0102010101",
        "0012110011",
        "0102001011",
        "1210100110",
        "1201011110",
        "1210111000",
        "2000100001",
        "1120011110",
        "1120001011",
        "1210101101"
    ];

    public R_4_7_48: string[] = ["22010010101",
        "10211110000",
        "12120100101",
        "10210001100",
        "10000111110",
        "21111000011",
        "12121011001",
        "21110111000",
        "11200010011",
        "11200010100",
        "01011011111",
        "22011101001",
        "22011101110",
        "21110111111",
        "02220111000",
        "21020001010",
        "10000111001",
        "20200100100",
        "01011011000",
        "21021110001",
        "02220111111",
        "00121101110",
        "00120010101",
        "02221000011",
        "01010100100",
        "21020001101",
        "21021110110",
        "12121011110",
        "02101110001",
        "22010010010",
        "00120010010",
        "12120100010",
        "11201101111",
        "10001000101",
        "00121101001",
        "21111000100",
        "01010100011",
        "10210001011",
        "10211110111",
        "02101110110",
        "20200100011",
        "20201011000",
        "02100001010",
        "11201101000",
        "20201011111",
        "02100001101",
        "02221000100",
        "10001000010"
    ];

    public R_4_7_144: string[] = ["00001111011",
        "00001111100",
        "00000000011",
        "00000000100",
        "00000011101",
        "00000011010",
        "00001100101",
        "00001100010",
        "00000101110",
        "00000101001",
        "00001010110",
        "00001010001",
        "00000110111",
        "00000110000",
        "00001001111",
        "00001001000",
        "11100011011",
        "11100011100",
        "11101100011",
        "11101100100",
        "11101111101",
        "11101111010",
        "11100000101",
        "11100000010",
        "11101001110",
        "11101001001",
        "11100110110",
        "11100110001",
        "11101010111",
        "11101010000",
        "11100101111",
        "11100101000",
        "22200101011",
        "22200101100",
        "22201010011",
        "22201010100",
        "22201001101",
        "22201001010",
        "22200110101",
        "22200110010",
        "22201111110",
        "22201111001",
        "22200000110",
        "22200000001",
        "22201100111",
        "22201100000",
        "22200011111",
        "22200011000",
        "21010110011",
        "21010110100",
        "21011001011",
        "21011001100",
        "21011010101",
        "21011010010",
        "21010101101",
        "21010101010",
        "21011100110",
        "21011100001",
        "21010011110",
        "21010011001",
        "21011111111",
        "21011111000",
        "21010000111",
        "21010000000",
        "02110111111",
        "02110111000",
        "02111000111",
        "02111000000",
        "02111011001",
        "02111011110",
        "02110100001",
        "02110100110",
        "02111101010",
        "02111101101",
        "02110010010",
        "02110010101",
        "02111110011",
        "02111110100",
        "02110001011",
        "02110001100",
        "10210111001",
        "10210111110",
        "10211000001",
        "10211000110",
        "10211011111",
        "10211011000",
        "10210100111",
        "10210100000",
        "10211101100",
        "10211101011",
        "10210010100",
        "10210010011",
        "10211110101",
        "10211110010",
        "10210001101",
        "10210001010",
        "12020111010",
        "12020111101",
        "12021000010",
        "12021000101",
        "12021011100",
        "12021011011",
        "12020100100",
        "12020100011",
        "12021101111",
        "12021101000",
        "12020010111",
        "12020010000",
        "12021110110",
        "12021110001",
        "12020001110",
        "12020001001",
        "20120111000",
        "20120111111",
        "20121000000",
        "20121000111",
        "20121011110",
        "20121011001",
        "20120100110",
        "20120100001",
        "20121101101",
        "20121101010",
        "20120010101",
        "20120010010",
        "20121110100",
        "20121110011",
        "20120001100",
        "20120001011",
        "01220100010",
        "01220100101",
        "01221011010",
        "01221011101",
        "01221000100",
        "01221000011",
        "01220111100",
        "01220111011",
        "01221110111",
        "01221110000",
        "01220001111",
        "01220001000",
        "01221101110",
        "01221101001",
        "01220010110",
        "01220010001"
    ];

    public R_5_0_18: string[] = ["20002",
        "12020",
        "01101",
        "12002",
        "01200",
        "01011",
        "01212",
        "12221",
        "20200",
        "20122",
        "20011",
        "20221",
        "12101",
        "12212",
        "01122",
        "01020",
        "12110",
        "20110"
    ];

    public R_5_3_36: string[] = ["00121111",
        "10022100",
        "00000110",
        "12100101",
        "11201111",
        "21101100",
        "20212010",
        "10020011",
        "02211100",
        "22221001",
        "20210101",
        "11202111",
        "11110110",
        "00122111",
        "21100011",
        "00001001",
        "01020101",
        "10021100",
        "12101010",
        "22220110",
        "01022010",
        "21102100",
        "02210011",
        "22010000",
        "22011111",
        "01021010",
        "22222001",
        "02212100",
        "11112001",
        "11111001",
        "20211010",
        "00002001",
        "22012111",
        "12102010",
        "00120000",
        "11200000"
    ];

    public R_5_5_108: string[] = ["2100201100",
        "0210201100",
        "1020201100",
        "1200210000",
        "2010210000",
        "0120210000",
        "2101210000",
        "0211210000",
        "1021210000",
        "1201201100",
        "2011201100",
        "0121201100",
        "0002211010",
        "1112211010",
        "2222211010",
        "0002200110",
        "1112200110",
        "2222200110",
        "2100211111",
        "0210211111",
        "1020211111",
        "1200200011",
        "2010200011",
        "0120200011",
        "2101200011",
        "0211200011",
        "1021200011",
        "1201211111",
        "2011211111",
        "0121211111",
        "0002201001",
        "1112201001",
        "2222201001",
        "0002210101",
        "1112210101",
        "2222210101",
        "1200101100",
        "2010101100",
        "0120101100",
        "2100110000",
        "0210110000",
        "1020110000",
        "1201110000",
        "2011110000",
        "0121110000",
        "2101101100",
        "0211101100",
        "1021101100",
        "0002111010",
        "1112111010",
        "2222111010",
        "0002100110",
        "1112100110",
        "2222100110",
        "1200111111",
        "2010111111",
        "0120111111",
        "2100100011",
        "0210100011",
        "1020100011",
        "1201100011",
        "2011100011",
        "0121100011",
        "2101111111",
        "0211111111",
        "1021111111",
        "0002101001",
        "1112101001",
        "2222101001",
        "0002110101",
        "1112110101",
        "2222110101",
        "1200010110",
        "2010010110",
        "0120010110",
        "2100001010",
        "0210001010",
        "1020001010",
        "1201001010",
        "2011001010",
        "0121001010",
        "2101010110",
        "0211010110",
        "1021010110",
        "0002000000",
        "1112000000",
        "2222000000",
        "0002011100",
        "1112011100",
        "2222011100",
        "1200000101",
        "2010000101",
        "0120000101",
        "2100011001",
        "0210011001",
        "1020011001",
        "1201011001",
        "2011011001",
        "0121011001",
        "2101000101",
        "0211000101",
        "1021000101",
        "0002010011",
        "1112010011",
        "2222010011",
        "0002001111",
        "1112001111",
        "2222001111"
    ];

    public R_6_3_24: string[] = ["222000000",
        "000111000",
        "111222000",
        "111000111",
        "222111111",
        "000222111",
        "220200011",
        "001011011",
        "112122011",
        "110100100",
        "221211100",
        "002022100",
        "220020101",
        "001101101",
        "112212101",
        "110010010",
        "221121010",
        "002202010",
        "220002110",
        "001110110",
        "112221110",
        "110001001",
        "221112001",
        "002220001"
    ];

    public R_6_3_72: string[] = ["110022000",
        "002100000",
        "002211101",
        "020211000",
        "011010110",
        "011001100",
        "011121110",
        "200001110",
        "101022101",
        "200220110",
        "002022111",
        "020022010",
        "221211010",
        "221100111",
        "110100000",
        "122010001",
        "212211111",
        "011202110",
        "122112100",
        "122220100",
        "221100000",
        "011010001",
        "200010100",
        "212022101",
        "200202100",
        "011112011",
        "002100111",
        "011001011",
        "122202001",
        "002211010",
        "221022111",
        "200112110",
        "122121110",
        "122001011",
        "110100111",
        "122121001",
        "110022111",
        "200220001",
        "101211111",
        "122001100",
        "122010110",
        "020100010",
        "122220011",
        "101022010",
        "011220100",
        "200202011",
        "200121011",
        "110211101",
        "020100101",
        "212100101",
        "212022010",
        "011202001",
        "122202110",
        "200112001",
        "200010011",
        "200001001",
        "101100101",
        "011121001",
        "122112011",
        "101211000",
        "011220011",
        "020211111",
        "011112100",
        "221022000",
        "221211101",
        "101100010",
        "002022000",
        "110211010",
        "200121100",
        "212100010",
        "020022101",
        "212211000"
    ];

    public R_6_4_44: string[] = ["1222221000",
        "1222220111",
        "1201110010",
        "1201111101",
        "0000221100",
        "0000220011",
        "0002021010",
        "0002020101",
        "0002200110",
        "0002201001",
        "0001110000",
        "0001111111",
        "0121120110",
        "0121121001",
        "0121211010",
        "0121210101",
        "0122110011",
        "0122111100",
        "0120001111",
        "0120000000",
        "1211000011",
        "1211001100",
        "1210101010",
        "1210100101",
        "1210010110",
        "1210011001",
        "1212221111",
        "1212220000",
        "2102201001",
        "2102200110",
        "2102020101",
        "2102021010",
        "2100221100",
        "2100220011",
        "2101110000",
        "2101111111",
        "2021120110",
        "2021121001",
        "2021211010",
        "2021210101",
        "2022110011",
        "2022111100",
        "2020001111",
        "2020000000"
    ];

    public R_7_0_36: string[] = ["0000000",
        "0000111",
        "0000222",
        "0121012",
        "0121120",
        "0121201",
        "0212000",
        "0212111",
        "0212222",
        "0210021",
        "0210102",
        "0210210",
        "1022021",
        "1022102",
        "1022210",
        "1110012",
        "1110120",
        "1110201",
        "1101000",
        "1101111",
        "1101222",
        "1201012",
        "1201120",
        "1201201",
        "2011021",
        "2011102",
        "2011210",
        "2022012",
        "2022120",
        "2022201",
        "2102021",
        "2102102",
        "2102210",
        "2220000",
        "2220111",
        "2220222"
    ];

    public R_7_2_34: string[] = ["000010001",
        "222000000",
        "012222000",
        "120222000",
        "201222000",
        "000020010",
        "111000011",
        "102111011",
        "210111011",
        "021111011",
        "012120100",
        "120120100",
        "201120100",
        "012201100",
        "120201100",
        "201201100",
        "102012111",
        "210012111",
        "021012111",
        "222221111",
        "000202111",
        "111221111",
        "222112200",
        "000101200",
        "111112200",
        "012021200",
        "120021200",
        "201021200",
        "102210211",
        "210210211",
        "021210211",
        "102102211",
        "210102211",
        "021102211"
    ];

    public R_7_2_108: string[] = ["110122200",
        "101121001",
        "201200101",
        "222100000",
        "200100210",
        "221220010",
        "100002201",
        "102211001",
        "101212211",
        "010221201",
        "022121210",
        "220111101",
        "011002210",
        "211020100",
        "021121111",
        "002202211",
        "010000100",
        "012102101",
        "111210011",
        "200221011",
        "112012200",
        "001122211",
        "000211110",
        "201221110",
        "020210010",
        "100121100",
        "111122101",
        "210222111",
        "211222210",
        "012221000",
        "222112011",
        "222012110",
        "002122010",
        "021012001",
        "200112201",
        "210110001",
        "002220200",
        "202101100",
        "210210100",
        "202110111",
        "122210201",
        "100020210",
        "002101001",
        "120022110",
        "202010210",
        "111110110",
        "010012111",
        "110000001",
        "000011011",
        "110101211",
        "211002111",
        "001010101",
        "100100111",
        "122010000",
        "112021211",
        "000002000",
        "212011010",
        "102020111",
        "201022000",
        "020001200",
        "012200011",
        "220211200",
        "120102011",
        "020010211",
        "022201111",
        "102202010",
        "111201000",
        "120120000",
        "200212000",
        "101112010",
        "112212101",
        "212111211",
        "202022201",
        "122201210",
        "112200110",
        "212102200",
        "201001011",
        "121202100",
        "011120011",
        "221021200",
        "110021010",
        "121111210",
        "000220101",
        "012120110",
        "011111000",
        "220220211",
        "021112100",
        "121211111",
        "011011201",
        "222021101",
        "121120201",
        "220102110",
        "010212210",
        "020222001",
        "001110200",
        "022222100",
        "221202001",
        "021000010",
        "001001110",
        "211201201",
        "212020001",
        "122022011",
        "101200200",
        "221000211",
        "210101010",
        "022100201",
        "120001101",
        "102011100"
    ];

    public R_8_0_27: string[] = ["12111201",
        "11100002",
        "10201121",
        "01120220",
        "02210110",
        "10010112",
        "20102212",
        "02022101",
        "11021011",
        "22200001",
        "00221012",
        "12002210",
        "00112021",
        "21110111",
        "10122100",
        "22121010",
        "00000000",
        "02101122",
        "21222102",
        "12220222",
        "20020221",
        "11212020",
        "01011202",
        "01202211",
        "20211200",
        "21001120",
        "22012022"
    ];

    public R_8_0_81: string[] = ["00000000",
        "00011111",
        "00022222",
        "00102211",
        "00110022",
        "00121100",
        "00201122",
        "00212200",
        "00220011",
        "01001212",
        "01012020",
        "01020101",
        "01100120",
        "01111201",
        "01122012",
        "01202001",
        "01210112",
        "01221220",
        "02002121",
        "02010202",
        "02021010",
        "02101002",
        "02112110",
        "02120221",
        "02200210",
        "02211021",
        "02222102",
        "10001221",
        "10012002",
        "10020110",
        "10100102",
        "10111210",
        "10122021",
        "10202010",
        "10210121",
        "10221202",
        "11002100",
        "11010211",
        "11021022",
        "11101011",
        "11112122",
        "11120200",
        "11200222",
        "11211000",
        "11222111",
        "12000012",
        "12011120",
        "12022201",
        "12102220",
        "12110001",
        "12121112",
        "12201101",
        "12212212",
        "12220020",
        "20002112",
        "20010220",
        "20021001",
        "20101020",
        "20112101",
        "20120212",
        "20200201",
        "20211012",
        "20222120",
        "21000021",
        "21011102",
        "21022210",
        "21102202",
        "21110010",
        "21121121",
        "21201110",
        "21212221",
        "21220002",
        "22001200",
        "22012011",
        "22020122",
        "22100111",
        "22111222",
        "22122000",
        "22202022",
        "22210100",
        "22221211"
    ];

    public R_8_2_324: string[] = ["1100201201",
        "2012110000",
        "0202111111",
        "0110011000",
        "0100212010",
        "1022102201",
        "0110102201",
        "2201011011",
        "2001220010",
        "1012212010",
        "2210100111",
        "0222201011",
        "2021202111",
        "2101210111",
        "2210100100",
        "0202222210",
        "2010201011",
        "1220001100",
        "1111111111",
        "1101201011",
        "0002212000",
        "0020120211",
        "1021220000",
        "2102122001",
        "2020111111",
        "2122202110",
        "1010000010",
        "1110111001",
        "1101012110",
        "1222001001",
        "2211002210",
        "2121121201",
        "1212000000",
        "0022002200",
        "2000021200",
        "2221212010",
        "0220022011",
        "0101010101",
        "2100122111",
        "2112022010",
        "1120011010",
        "0001212201",
        "2112002101",
        "0002000201",
        "2211012001",
        "0200020001",
        "0020201010",
        "1120200211",
        "2020202001",
        "1210121000",
        "2121000000",
        "2000112101",
        "0200202111",
        "2220010200",
        "1210121011",
        "1011121010",
        "2212120210",
        "1102022011",
        "2021010210",
        "1202120200",
        "0221201201",
        "1222210100",
        "1112010210",
        "0012122100",
        "0112011201",
        "0211011010",
        "2121000011",
        "2100210001",
        "2020111100",
        "2010022201",
        "1112202111",
        "0220100110",
        "1001211210",
        "2112211211",
        "1020102011",
        "1102022000",
        "0120221100",
        "1020220201",
        "1101022201",
        "1200211200",
        "2202210110",
        "0102010211",
        "1000120210",
        "1002002210",
        "2201102201",
        "0121120210",
        "1022122110",
        "1001100100",
        "2022111001",
        "0110011011",
        "0211200211",
        "2212100001",
        "1021220011",
        "1020210110",
        "2111201010",
        "0112220000",
        "0120221111",
        "2112211200",
        "1012101200",
        "0000121201",
        "0000111110",
        "1001100111",
        "1201120101",
        "0202111100",
        "0010210100",
        "1202120211",
        "0212102010",
        "1200002101",
        "2220121010",
        "2002011010",
        "2022020111",
        "0201101210",
        "2221101211",
        "1110020111",
        "2121111110",
        "0201020100",
        "0100101200",
        "0200010210",
        "0111102000",
        "1000100001",
        "2120212011",
        "2212012111",
        "2120000201",
        "1012101211",
        "1002221100",
        "1000012111",
        "0011122001",
        "2021202100",
        "2012201201",
        "2221222101",
        "2111120200",
        "2111211101",
        "0200202100",
        "1111222210",
        "0111210110",
        "1120200200",
        "1211212000",
        "1100110011",
        "0010210111",
        "2010201000",
        "2200220000",
        "2000102010",
        "0211021101",
        "2202102011",
        "1000012100",
        "2102001100",
        "1011010200",
        "0102010200",
        "1121112101",
        "0022110010",
        "0012210001",
        "0100101211",
        "1210212201",
        "0012122111",
        "2201011000",
        "1010010101",
        "1021011201",
        "0101222211",
        "0222012110",
        "0121012100",
        "0100222101",
        "0212021211",
        "2220010211",
        "0121012111",
        "1222210111",
        "2120020110",
        "1111111100",
        "0001121000",
        "0221110000",
        "0222022201",
        "1221210001",
        "2011110201",
        "0110122110",
        "2022020100",
        "0222201000",
        "1211000201",
        "1100110000",
        "1201002200",
        "0112220011",
        "1111202001",
        "0120012001",
        "2222222211",
        "1120021101",
        "2001112200",
        "1201110010",
        "0011001111",
        "1122200101",
        "2010012110",
        "1212000011",
        "1110020100",
        "0210200101",
        "2201122110",
        "1220122001",
        "1010222211",
        "1121021200",
        "2202220201",
        "1002221111",
        "0022120101",
        "1011101101",
        "0112001110",
        "0021211211",
        "0211200200",
        "0212021200",
        "0011001100",
        "2000021211",
        "0021002101",
        "1021001110",
        "1200022010",
        "1102110201",
        "2100122100",
        "2021020001",
        "0221221110",
        "2020222210",
        "0122100100",
        "0021211200",
        "2212012100",
        "2102001111",
        "2022101210",
        "0120002210",
        "2222222200",
        "2011022011",
        "0220110201",
        "0212112101",
        "2220101101",
        "2101210100",
        "1121021211",
        "1212121201",
        "1020102000",
        "2101001001",
        "2210211210",
        "1112020001",
        "0102121010",
        "1022011000",
        "2100200210",
        "2002200211",
        "2122121000",
        "2120212000",
        "1122112211",
        "1221122111",
        "0121100001",
        "1012222101",
        "1200211211",
        "0201111001",
        "1211212011",
        "1122112200",
        "0122100111",
        "1221200210",
        "1220001111",
        "2002021101",
        "1001221001",
        "0000000011",
        "0001202110",
        "0102101101",
        "2211221111",
        "1011010211",
        "0020211101",
        "1100221110",
        "0020120200",
        "2200011201",
        "2221101200",
        "1202211101",
        "0111102011",
        "2102112210",
        "1220112210",
        "1102100110",
        "0210112211",
        "2101021210",
        "0210220010",
        "0000000000",
        "1202201010",
        "0111220201",
        "2001112211",
        "0101000010",
        "1121102010",
        "0011112210",
        "0021022010",
        "0202202001",
        "2222010101",
        "1022011011",
        "2011100110",
        "1212111110",
        "2001200101",
        "0010021210",
        "0201020111",
        "2110002200",
        "2122212201",
        "2211221100",
        "1122220010",
        "2110110010",
        "2111120211",
        "2200001110",
        "2202102000",
        "2210221001",
        "0221110011",
        "0002020110",
        "0001121011",
        "0002212011",
        "0101222200",
        "0122221001",
        "1221122100",
        "1002012001",
        "2012221110",
        "2110120101",
        "2011022000",
        "1211020110",
        "2222000010",
        "2012110011",
        "1112202100",
        "0210112200",
        "1201002211",
        "2200220011",
        "0010001001",
        "1010222200",
        "1101201000",
        "0122211210",
        "0220022000",
        "2002200200",
        "1110101210",
        "2110002211",
        "0022002211",
        "2122121011",
        "1222021210",
        "0012200210",
        "1210202110"
    ];

    public R_8_3_504: string[] = ["00000000111",
        "11100000111",
        "22200000111",
        "21002000111",
        "02102000111",
        "10202000111",
        "12021100111",
        "20121100111",
        "01221100111",
        "12011200111",
        "20111200111",
        "01211200111",
        "00020010111",
        "11120010111",
        "22220010111",
        "21020210111",
        "02120210111",
        "10220210111",
        "21002210111",
        "02102210111",
        "10202210111",
        "00012120111",
        "11112120111",
        "22212120111",
        "12022120111",
        "20122120111",
        "01222120111",
        "00011220111",
        "11111220111",
        "22211220111",
        "21022001111",
        "02122001111",
        "10222001111",
        "00000201111",
        "11100201111",
        "22200201111",
        "21020201111",
        "02120201111",
        "10220201111",
        "00022011111",
        "11122011111",
        "22222011111",
        "12010111111",
        "20110111111",
        "01210111111",
        "21011111111",
        "02111111111",
        "10211111111",
        "00002211111",
        "11102211111",
        "22202211111",
        "12010021111",
        "20110021111",
        "01210021111",
        "12001021111",
        "20101021111",
        "01201021111",
        "21001121111",
        "02101121111",
        "10201121111",
        "00021102111",
        "11121102111",
        "22221102111",
        "00012102111",
        "11112102111",
        "22212102111",
        "12012202111",
        "20112202111",
        "01212202111",
        "12001012111",
        "20101012111",
        "01201012111",
        "21011012111",
        "02111012111",
        "10211012111",
        "12000112111",
        "20100112111",
        "01200112111",
        "21010022111",
        "02110022111",
        "10210022111",
        "21000122111",
        "02100122111",
        "10200122111",
        "00021222111",
        "11121222111",
        "22221222111",
        "12022222111",
        "20122222111",
        "01222222111",
        "21010100011",
        "02110100011",
        "10210100011",
        "00022200011",
        "11122200011",
        "22222200011",
        "12012010011",
        "20112010011",
        "01212010011",
        "00001110011",
        "11101110011",
        "22201110011",
        "21021020011",
        "02121020011",
        "10221020011",
        "12000220011",
        "20100220011",
        "01200220011",
        "00011001011",
        "11111001011",
        "22211001011",
        "12002101011",
        "20102101011",
        "01202101011",
        "21000011011",
        "02100011011",
        "10200011011",
        "12021211011",
        "20121211011",
        "01221211011",
        "00020121011",
        "11120121011",
        "22220121011",
        "21012221011",
        "02112221011",
        "10212221011",
        "12020002011",
        "20120002011",
        "01220002011",
        "21001202011",
        "02101202011",
        "10201202011",
        "21022112011",
        "02122112011",
        "10222112011",
        "00010212011",
        "11110212011",
        "22210212011",
        "00002022011",
        "11102022011",
        "22202022011",
        "12011122011",
        "20111122011",
        "01211122011",
        "21010100101",
        "02110100101",
        "10210100101",
        "00022200101",
        "11122200101",
        "22222200101",
        "12012010101",
        "20112010101",
        "01212010101",
        "00001110101",
        "11101110101",
        "22201110101",
        "21021020101",
        "02121020101",
        "10221020101",
        "12000220101",
        "20100220101",
        "01200220101",
        "00011001101",
        "11111001101",
        "22211001101",
        "12002101101",
        "20102101101",
        "01202101101",
        "21000011101",
        "02100011101",
        "10200011101",
        "12021211101",
        "20121211101",
        "01221211101",
        "00020121101",
        "11120121101",
        "22220121101",
        "21012221101",
        "02112221101",
        "10212221101",
        "12020002101",
        "20120002101",
        "01220002101",
        "21001202101",
        "02101202101",
        "10201202101",
        "21022112101",
        "02122112101",
        "10222112101",
        "00010212101",
        "11110212101",
        "22210212101",
        "00002022101",
        "11102022101",
        "22202022101",
        "12011122101",
        "20111122101",
        "01211122101",
        "12001000001",
        "20101000001",
        "01201000001",
        "00022200001",
        "11122200001",
        "22222200001",
        "12020110001",
        "20120110001",
        "01220110001",
        "21011210001",
        "02111210001",
        "10211210001",
        "00010020001",
        "11110020001",
        "22210020001",
        "21002120001",
        "02102120001",
        "10202120001",
        "21021101001",
        "02121101001",
        "10221101001",
        "12010201001",
        "20110201001",
        "01210201001",
        "21000011001",
        "02100011001",
        "10200011001",
        "00012111001",
        "11112111001",
        "22212111001",
        "12022021001",
        "20122021001",
        "01222021001",
        "00001221001",
        "11101221001",
        "22201221001",
        "21012002001",
        "02112002001",
        "10212002001",
        "00000102001",
        "11100102001",
        "22200102001",
        "00021012001",
        "11121012001",
        "22221012001",
        "12002212001",
        "20102212001",
        "01202212001",
        "12011122001",
        "20111122001",
        "01211122001",
        "21020222001",
        "02120222001",
        "10220222001",
        "21010100110",
        "02110100110",
        "10210100110",
        "00022200110",
        "11122200110",
        "22222200110",
        "12012010110",
        "20112010110",
        "01212010110",
        "00001110110",
        "11101110110",
        "22201110110",
        "21021020110",
        "02121020110",
        "10221020110",
        "12000220110",
        "20100220110",
        "01200220110",
        "00011001110",
        "11111001110",
        "22211001110",
        "12002101110",
        "20102101110",
        "01202101110",
        "21000011110",
        "02100011110",
        "10200011110",
        "12021211110",
        "20121211110",
        "01221211110",
        "00020121110",
        "11120121110",
        "22220121110",
        "21012221110",
        "02112221110",
        "10212221110",
        "12020002110",
        "20120002110",
        "01220002110",
        "21001202110",
        "02101202110",
        "10201202110",
        "21022112110",
        "02122112110",
        "10222112110",
        "00010212110",
        "11110212110",
        "22210212110",
        "00002022110",
        "11102022110",
        "22202022110",
        "12011122110",
        "20111122110",
        "01211122110",
        "12001000010",
        "20101000010",
        "01201000010",
        "00022200010",
        "11122200010",
        "22222200010",
        "12020110010",
        "20120110010",
        "01220110010",
        "21011210010",
        "02111210010",
        "10211210010",
        "00010020010",
        "11110020010",
        "22210020010",
        "21002120010",
        "02102120010",
        "10202120010",
        "21021101010",
        "02121101010",
        "10221101010",
        "12010201010",
        "20110201010",
        "01210201010",
        "21000011010",
        "02100011010",
        "10200011010",
        "00012111010",
        "11112111010",
        "22212111010",
        "12022021010",
        "20122021010",
        "01222021010",
        "00001221010",
        "11101221010",
        "22201221010",
        "21012002010",
        "02112002010",
        "10212002010",
        "00000102010",
        "11100102010",
        "22200102010",
        "00021012010",
        "11121012010",
        "22221012010",
        "12002212010",
        "20102212010",
        "01202212010",
        "12011122010",
        "20111122010",
        "01211122010",
        "21020222010",
        "02120222010",
        "10220222010",
        "12001000100",
        "20101000100",
        "01201000100",
        "00022200100",
        "11122200100",
        "22222200100",
        "12020110100",
        "20120110100",
        "01220110100",
        "21011210100",
        "02111210100",
        "10211210100",
        "00010020100",
        "11110020100",
        "22210020100",
        "21002120100",
        "02102120100",
        "10202120100",
        "21021101100",
        "02121101100",
        "10221101100",
        "12010201100",
        "20110201100",
        "01210201100",
        "21000011100",
        "02100011100",
        "10200011100",
        "00012111100",
        "11112111100",
        "22212111100",
        "12022021100",
        "20122021100",
        "01222021100",
        "00001221100",
        "11101221100",
        "22201221100",
        "21012002100",
        "02112002100",
        "10212002100",
        "00000102100",
        "11100102100",
        "22200102100",
        "00021012100",
        "11121012100",
        "22221012100",
        "12002212100",
        "20102212100",
        "01202212100",
        "12011122100",
        "20111122100",
        "01211122100",
        "21020222100",
        "02120222100",
        "10220222100",
        "21020000000",
        "02120000000",
        "10220000000",
        "00011100000",
        "11111100000",
        "22211100000",
        "12012100000",
        "20112100000",
        "01212100000",
        "21000200000",
        "02100200000",
        "10200200000",
        "00002010000",
        "11102010000",
        "22202010000",
        "21022010000",
        "02122010000",
        "10222010000",
        "00000210000",
        "11100210000",
        "22200210000",
        "00021120000",
        "11121120000",
        "22221120000",
        "12021220000",
        "20121220000",
        "01221220000",
        "12012220000",
        "20112220000",
        "01212220000",
        "00020001000",
        "11120001000",
        "22220001000",
        "00002001000",
        "11102001000",
        "22202001000",
        "21002201000",
        "02102201000",
        "10202201000",
        "12011011000",
        "20111011000",
        "01211011000",
        "12001111000",
        "20101111000",
        "01201111000",
        "00020211000",
        "11120211000",
        "22220211000",
        "21022211000",
        "02122211000",
        "10222211000",
        "21011021000",
        "02111021000",
        "10211021000",
        "12000121000",
        "20100121000",
        "01200121000",
        "21010121000",
        "02110121000",
        "10210121000",
        "12022102000",
        "20122102000",
        "01222102000",
        "00011202000",
        "11111202000",
        "22211202000",
        "12021202000",
        "20121202000",
        "01221202000",
        "12010012000",
        "20110012000",
        "01210012000",
        "21010112000",
        "02110112000",
        "10210112000",
        "21001112000",
        "02101112000",
        "10201112000",
        "12000022000",
        "20100022000",
        "01200022000",
        "21001022000",
        "02101022000",
        "10201022000",
        "00022122000",
        "11122122000",
        "22222122000",
        "00012222000",
        "11112222000",
        "22212222000"
    ];

    public R_9_0_222: string[] = ["000000111",
        "000000222",
        "000011001",
        "000022002",
        "000101010",
        "000110100",
        "000111222",
        "000202020",
        "000220200",
        "000222111",
        "001001100",
        "001010010",
        "001021122",
        "001100001",
        "001102212",
        "001122021",
        "001210221",
        "001212102",
        "001221210",
        "002002200",
        "002012211",
        "002020020",
        "002112120",
        "002120112",
        "002121201",
        "002200002",
        "002201121",
        "002211012",
        "010000000",
        "010021212",
        "010102221",
        "010122102",
        "010210122",
        "010212210",
        "010221021",
        "011012022",
        "011022201",
        "011111111",
        "011120220",
        "011201202",
        "011202120",
        "011220012",
        "012002112",
        "012011220",
        "012020121",
        "012101022",
        "012110202",
        "012122010",
        "012200211",
        "012212001",
        "012221100",
        "020000000",
        "020012121",
        "020112012",
        "020120211",
        "020121120",
        "020201112",
        "020211201",
        "021001221",
        "021010212",
        "021022110",
        "021100122",
        "021112200",
        "021121002",
        "021202011",
        "021211020",
        "021220101",
        "022011102",
        "022021011",
        "022101210",
        "022102101",
        "022110021",
        "022210110",
        "022222222",
        "100000000",
        "100021221",
        "100102122",
        "100122210",
        "100210212",
        "100212021",
        "100221102",
        "101012220",
        "101022012",
        "101111111",
        "101120202",
        "101201022",
        "101202201",
        "101220120",
        "102001212",
        "102010122",
        "102022101",
        "102100221",
        "102112002",
        "102121020",
        "102202110",
        "102211200",
        "102220011",
        "110011110",
        "110012202",
        "110022120",
        "110101101",
        "110110011",
        "110120022",
        "110201220",
        "110202012",
        "110220201",
        "111000222",
        "111001011",
        "111010101",
        "111100110",
        "111111000",
        "111111222",
        "111122112",
        "111212121",
        "111221211",
        "111222000",
        "112002021",
        "112020210",
        "112021002",
        "112102200",
        "112112211",
        "112121121",
        "112200102",
        "112210020",
        "112211112",
        "120002211",
        "120011022",
        "120020112",
        "120101202",
        "120110220",
        "120122001",
        "120200121",
        "120212100",
        "120221010",
        "121002102",
        "121020021",
        "121021200",
        "121102020",
        "121111111",
        "121200210",
        "121210002",
        "122001120",
        "122010201",
        "122012010",
        "122100012",
        "122120100",
        "122201001",
        "122222222",
        "200000000",
        "200012112",
        "200112201",
        "200120121",
        "200121012",
        "200201211",
        "200211120",
        "201002121",
        "201011202",
        "201020211",
        "201101220",
        "201110022",
        "201122100",
        "201200112",
        "201212010",
        "201221001",
        "202011021",
        "202021110",
        "202101102",
        "202102011",
        "202110210",
        "202210101",
        "202222222",
        "210001122",
        "210010221",
        "210022011",
        "210100212",
        "210112020",
        "210121200",
        "210202101",
        "210211002",
        "210220110",
        "211002210",
        "211020102",
        "211021020",
        "211102002",
        "211111111",
        "211200021",
        "211210200",
        "212001201",
        "212010012",
        "212012100",
        "212100120",
        "212120001",
        "212201010",
        "212222222",
        "220011210",
        "220021101",
        "220022220",
        "220101021",
        "220102110",
        "220110102",
        "220202202",
        "220210011",
        "220220022",
        "221001012",
        "221010120",
        "221012001",
        "221100201",
        "221120010",
        "221122221",
        "221201100",
        "221212212",
        "221221122",
        "222000111",
        "222002022",
        "222020202",
        "222111000",
        "222112122",
        "222121212",
        "222200220",
        "222211221",
        "222222000",
        "222222111"
    ];

    public R_9_0_243: string[] = ["110001100",
        "111221210",
        "211210220",
        "210110212",
        "002102112",
        "011011221",
        "012122010",
        "220100110",
        "120002112",
        "120021001",
        "121020102",
        "212220102",
        "211112010",
        "211202112",
        "102211221",
        "112201122",
        "111101111",
        "110020022",
        "100120220",
        "122221020",
        "111112222",
        "112021221",
        "111022120",
        "000011111",
        "200120011",
        "221121100",
        "121200000",
        "202110102",
        "121001210",
        "222011210",
        "022011001",
        "201002010",
        "002121001",
        "200000212",
        "112010110",
        "210001221",
        "202102021",
        "210200011",
        "201122112",
        "201220022",
        "200112200",
        "212111111",
        "202200201",
        "122022200",
        "100112112",
        "120111100",
        "200011020",
        "011022002",
        "021222101",
        "010102222",
        "221020220",
        "012220220",
        "010121111",
        "011221122",
        "011112101",
        "100202211",
        "101010000",
        "012201001",
        "001201221",
        "002110220",
        "110110121",
        "221110022",
        "220111221",
        "001122200",
        "221211202",
        "211221001",
        "022112211",
        "101002222",
        "120220121",
        "122000011",
        "120122211",
        "000000000",
        "000120102",
        "212212021",
        "220122002",
        "102001020",
        "022210121",
        "201201100",
        "112122101",
        "101021111",
        "022022112",
        "212021012",
        "220212101",
        "121102120",
        "021012200",
        "011202200",
        "120201202",
        "121121012",
        "000202120",
        "212010201",
        "021001122",
        "222210000",
        "122202101",
        "111000201",
        "212201210",
        "020002021",
        "012002211",
        "100000121",
        "200210110",
        "012100121",
        "101122021",
        "021110110",
        "001111122",
        "112111020",
        "011000110",
        "121222222",
        "210222200",
        "110102010",
        "201021202",
        "102102200",
        "120212010",
        "220010011",
        "001212002",
        "011120212",
        "202012222",
        "202121210",
        "020201111",
        "222101012",
        "020212222",
        "010001012",
        "202020000",
        "112002002",
        "200101122",
        "000210201",
        "020010102",
        "001100011",
        "102012101",
        "022000220",
        "210102101",
        "010200102",
        "211101202",
        "100221100",
        "220021122",
        "020122120",
        "210012002",
        "022120022",
        "020220000",
        "212100000",
        "002211100",
        "120010220",
        "101100102",
        "021211020",
        "002200022",
        "201111001",
        "021102002",
        "121012021",
        "202211012",
        "012111202",
        "001002101",
        "010020201",
        "010012120",
        "111202021",
        "000112021",
        "112100212",
        "000101210",
        "212122222",
        "201010121",
        "101212120",
        "111210102",
        "222000102",
        "102020212",
        "021121221",
        "020021210",
        "011210011",
        "200022101",
        "201212211",
        "020111012",
        "020100201",
        "111120000",
        "102222002",
        "010211210",
        "120100022",
        "211120121",
        "010110000",
        "222112120",
        "002001202",
        "221200121",
        "002012010",
        "200202002",
        "000221012",
        "102121122",
        "211000022",
        "102110011",
        "200221221",
        "122210212",
        "110121202",
        "211022211",
        "011101020",
        "101220201",
        "021200212",
        "201100220",
        "101111210",
        "002222211",
        "100022010",
        "221012112",
        "102200110",
        "022101100",
        "002020121",
        "001010212",
        "022202010",
        "001220110",
        "110012211",
        "012021100",
        "112212200",
        "202222120",
        "100210022",
        "221102211",
        "211011100",
        "001021020",
        "122112002",
        "012010022",
        "100011202",
        "210020110",
        "222221111",
        "021020011",
        "221001001",
        "122120110",
        "220220212",
        "122101221",
        "111011012",
        "112220011",
        "222120201",
        "220002200",
        "220201020",
        "110222112",
        "222022021",
        "202001111",
        "210121020",
        "101201012",
        "122011122",
        "100101001",
        "222202222",
        "012212112",
        "221222010",
        "121110201",
        "022221202",
        "210211122",
        "110200220",
        "121211111",
        "110211001",
        "010222021",
        "212002120",
        "000022222"
    ];

    public R_9_0_1356: string[] = ["000000000",
        "222000000",
        "011001000",
        "200001000",
        "112002000",
        "121002000",
        "020010000",
        "101010000",
        "022011000",
        "202011000",
        "210012000",
        "112020000",
        "211020000",
        "120021000",
        "002022000",
        "221022000",
        "002100000",
        "110100000",
        "022101000",
        "220101000",
        "201102000",
        "202110000",
        "220110000",
        "000111000",
        "111111000",
        "011112000",
        "122112000",
        "021120000",
        "101121000",
        "212121000",
        "010122000",
        "100122000",
        "121200000",
        "211200000",
        "102201000",
        "020202000",
        "212202000",
        "012210000",
        "110211000",
        "221211000",
        "001212000",
        "100212000",
        "122220000",
        "200220000",
        "001221000",
        "010221000",
        "111222000",
        "222222000",
        "111000001",
        "222000001",
        "020001001",
        "101001001",
        "010002001",
        "202002001",
        "002010001",
        "110010001",
        "100011001",
        "211011001",
        "021012001",
        "001020001",
        "220020001",
        "012021001",
        "122022001",
        "200022001",
        "011100001",
        "200100001",
        "001101001",
        "112101001",
        "120102001",
        "010110001",
        "121110001",
        "000111001",
        "222111001",
        "101112001",
        "212112001",
        "102120001",
        "110121001",
        "221121001",
        "022122001",
        "211122001",
        "022200001",
        "100200001",
        "210201001",
        "002202001",
        "221202001",
        "201210001",
        "011211001",
        "122211001",
        "112212001",
        "220212001",
        "020220001",
        "212220001",
        "121221001",
        "202221001",
        "000222001",
        "111222001",
        "000000002",
        "111000002",
        "122001002",
        "212001002",
        "001002002",
        "220002002",
        "212010002",
        "221010002",
        "010011002",
        "121011002",
        "102012002",
        "022020002",
        "100020002",
        "201021002",
        "011022002",
        "110022002",
        "122100002",
        "221100002",
        "100101002",
        "211101002",
        "012102002",
        "001110002",
        "112110002",
        "111111002",
        "222111002",
        "020112002",
        "200112002",
        "210120002",
        "002121002",
        "020121002",
        "121122002",
        "202122002",
        "010200002",
        "202200002",
        "021201002",
        "101202002",
        "110202002",
        "120210002",
        "002211002",
        "200211002",
        "022212002",
        "211212002",
        "011220002",
        "101220002",
        "112221002",
        "220221002",
        "000222002",
        "222222002",
        "012000010",
        "120000010",
        "201000010",
        "022002010",
        "100002010",
        "211002010",
        "001011010",
        "112011010",
        "220011010",
        "000012010",
        "111012010",
        "222012010",
        "010020010",
        "121020010",
        "202020010",
        "021021010",
        "102021010",
        "210021010",
        "010101010",
        "121101010",
        "202101010",
        "021102010",
        "102102010",
        "210102010",
        "022110010",
        "100110010",
        "211110010",
        "012111010",
        "120111010",
        "201111010",
        "000120010",
        "111120010",
        "222120010",
        "001122010",
        "112122010",
        "220122010",
        "001200010",
        "112200010",
        "220200010",
        "000201010",
        "111201010",
        "222201010",
        "021210010",
        "102210010",
        "210210010",
        "010212010",
        "121212010",
        "202212010",
        "022221010",
        "100221010",
        "211221010",
        "012222010",
        "120222010",
        "201222010",
        "111000011",
        "222000011",
        "020001011",
        "101001011",
        "010002011",
        "202002011",
        "002010011",
        "110010011",
        "100011011",
        "211011011",
        "021012011",
        "001020011",
        "220020011",
        "012021011",
        "122022011",
        "200022011",
        "011100011",
        "200100011",
        "001101011",
        "112101011",
        "120102011",
        "010110011",
        "121110011",
        "000111011",
        "222111011",
        "101112011",
        "212112011",
        "102120011",
        "110121011",
        "221121011",
        "022122011",
        "211122011",
        "022200011",
        "100200011",
        "210201011",
        "002202011",
        "221202011",
        "201210011",
        "011211011",
        "122211011",
        "112212011",
        "220212011",
        "020220011",
        "212220011",
        "121221011",
        "202221011",
        "000222011",
        "111222011",
        "000000012",
        "222000012",
        "011001012",
        "200001012",
        "112002012",
        "121002012",
        "020010012",
        "101010012",
        "022011012",
        "202011012",
        "210012012",
        "112020012",
        "211020012",
        "120021012",
        "002022012",
        "221022012",
        "002100012",
        "110100012",
        "022101012",
        "220101012",
        "201102012",
        "202110012",
        "220110012",
        "000111012",
        "111111012",
        "011112012",
        "122112012",
        "021120012",
        "101121012",
        "212121012",
        "010122012",
        "100122012",
        "121200012",
        "211200012",
        "102201012",
        "020202012",
        "212202012",
        "012210012",
        "110211012",
        "221211012",
        "001212012",
        "100212012",
        "122220012",
        "200220012",
        "001221012",
        "010221012",
        "111222012",
        "222222012",
        "000000020",
        "111000020",
        "122001020",
        "212001020",
        "001002020",
        "220002020",
        "212010020",
        "221010020",
        "010011020",
        "121011020",
        "102012020",
        "022020020",
        "100020020",
        "201021020",
        "011022020",
        "110022020",
        "122100020",
        "221100020",
        "100101020",
        "211101020",
        "012102020",
        "001110020",
        "112110020",
        "111111020",
        "222111020",
        "020112020",
        "200112020",
        "210120020",
        "002121020",
        "020121020",
        "121122020",
        "202122020",
        "010200020",
        "202200020",
        "021201020",
        "101202020",
        "110202020",
        "120210020",
        "002211020",
        "200211020",
        "022212020",
        "211212020",
        "011220020",
        "101220020",
        "112221020",
        "220221020",
        "000222020",
        "222222020",
        "021000021",
        "102000021",
        "210000021",
        "002001021",
        "110001021",
        "221001021",
        "011010021",
        "122010021",
        "200010021",
        "012012021",
        "120012021",
        "201012021",
        "000021021",
        "111021021",
        "222021021",
        "020022021",
        "101022021",
        "212022021",
        "020100021",
        "101100021",
        "212100021",
        "000102021",
        "111102021",
        "222102021",
        "021111021",
        "102111021",
        "210111021",
        "002112021",
        "110112021",
        "221112021",
        "012120021",
        "120120021",
        "201120021",
        "011121021",
        "122121021",
        "200121021",
        "012201021",
        "120201021",
        "201201021",
        "011202021",
        "122202021",
        "200202021",
        "000210021",
        "111210021",
        "222210021",
        "020211021",
        "101211021",
        "212211021",
        "002220021",
        "110220021",
        "221220021",
        "021222021",
        "102222021",
        "210222021",
        "012000022",
        "120000022",
        "201000022",
        "022002022",
        "100002022",
        "211002022",
        "001011022",
        "112011022",
        "220011022",
        "000012022",
        "111012022",
        "222012022",
        "010020022",
        "121020022",
        "202020022",
        "021021022",
        "102021022",
        "210021022",
        "010101022",
        "121101022",
        "202101022",
        "021102022",
        "102102022",
        "210102022",
        "022110022",
        "100110022",
        "211110022",
        "012111022",
        "120111022",
        "201111022",
        "000120022",
        "111120022",
        "222120022",
        "001122022",
        "112122022",
        "220122022",
        "001200022",
        "112200022",
        "220200022",
        "000201022",
        "111201022",
        "222201022",
        "021210022",
        "102210022",
        "210210022",
        "010212022",
        "121212022",
        "202212022",
        "022221022",
        "100221022",
        "211221022",
        "012222022",
        "120222022",
        "201222022",
        "021000100",
        "102000100",
        "210000100",
        "002001100",
        "110001100",
        "221001100",
        "011010100",
        "122010100",
        "200010100",
        "012012100",
        "120012100",
        "201012100",
        "000021100",
        "111021100",
        "222021100",
        "020022100",
        "101022100",
        "212022100",
        "020100100",
        "101100100",
        "212100100",
        "000102100",
        "111102100",
        "222102100",
        "021111100",
        "102111100",
        "210111100",
        "002112100",
        "110112100",
        "221112100",
        "012120100",
        "120120100",
        "201120100",
        "011121100",
        "122121100",
        "200121100",
        "012201100",
        "120201100",
        "201201100",
        "011202100",
        "122202100",
        "200202100",
        "000210100",
        "111210100",
        "222210100",
        "020211100",
        "101211100",
        "212211100",
        "002220100",
        "110220100",
        "221220100",
        "021222100",
        "102222100",
        "210222100",
        "012000101",
        "120000101",
        "201000101",
        "022002101",
        "100002101",
        "211002101",
        "001011101",
        "112011101",
        "220011101",
        "000012101",
        "111012101",
        "222012101",
        "010020101",
        "121020101",
        "202020101",
        "021021101",
        "102021101",
        "210021101",
        "010101101",
        "121101101",
        "202101101",
        "021102101",
        "102102101",
        "210102101",
        "022110101",
        "100110101",
        "211110101",
        "012111101",
        "120111101",
        "201111101",
        "000120101",
        "111120101",
        "222120101",
        "001122101",
        "112122101",
        "220122101",
        "001200101",
        "112200101",
        "220200101",
        "000201101",
        "111201101",
        "222201101",
        "021210101",
        "102210101",
        "210210101",
        "010212101",
        "121212101",
        "202212101",
        "022221101",
        "100221101",
        "211221101",
        "012222101",
        "120222101",
        "201222101",
        "000000102",
        "222000102",
        "011001102",
        "200001102",
        "112002102",
        "121002102",
        "020010102",
        "101010102",
        "022011102",
        "202011102",
        "210012102",
        "112020102",
        "211020102",
        "120021102",
        "002022102",
        "221022102",
        "002100102",
        "110100102",
        "022101102",
        "220101102",
        "201102102",
        "202110102",
        "220110102",
        "000111102",
        "111111102",
        "011112102",
        "122112102",
        "021120102",
        "101121102",
        "212121102",
        "010122102",
        "100122102",
        "121200102",
        "211200102",
        "102201102",
        "020202102",
        "212202102",
        "012210102",
        "110211102",
        "221211102",
        "001212102",
        "100212102",
        "122220102",
        "200220102",
        "001221102",
        "010221102",
        "111222102",
        "222222102",
        "000000110",
        "222000110",
        "011001110",
        "200001110",
        "112002110",
        "121002110",
        "020010110",
        "101010110",
        "022011110",
        "202011110",
        "210012110",
        "112020110",
        "211020110",
        "120021110",
        "002022110",
        "221022110",
        "002100110",
        "110100110",
        "022101110",
        "220101110",
        "201102110",
        "202110110",
        "220110110",
        "000111110",
        "111111110",
        "011112110",
        "122112110",
        "021120110",
        "101121110",
        "212121110",
        "010122110",
        "100122110",
        "121200110",
        "211200110",
        "102201110",
        "020202110",
        "212202110",
        "012210110",
        "110211110",
        "221211110",
        "001212110",
        "100212110",
        "122220110",
        "200220110",
        "001221110",
        "010221110",
        "111222110",
        "222222110",
        "000000111",
        "111000111",
        "122001111",
        "212001111",
        "001002111",
        "220002111",
        "212010111",
        "221010111",
        "010011111",
        "121011111",
        "102012111",
        "022020111",
        "100020111",
        "201021111",
        "011022111",
        "110022111",
        "122100111",
        "221100111",
        "100101111",
        "211101111",
        "012102111",
        "001110111",
        "112110111",
        "111111111",
        "222111111",
        "020112111",
        "200112111",
        "210120111",
        "002121111",
        "020121111",
        "121122111",
        "202122111",
        "010200111",
        "202200111",
        "021201111",
        "101202111",
        "110202111",
        "120210111",
        "002211111",
        "200211111",
        "022212111",
        "211212111",
        "011220111",
        "101220111",
        "112221111",
        "220221111",
        "000222111",
        "222222111",
        "021000112",
        "102000112",
        "210000112",
        "002001112",
        "110001112",
        "221001112",
        "011010112",
        "122010112",
        "200010112",
        "012012112",
        "120012112",
        "201012112",
        "000021112",
        "111021112",
        "222021112",
        "020022112",
        "101022112",
        "212022112",
        "020100112",
        "101100112",
        "212100112",
        "000102112",
        "111102112",
        "222102112",
        "021111112",
        "102111112",
        "210111112",
        "002112112",
        "110112112",
        "221112112",
        "012120112",
        "120120112",
        "201120112",
        "011121112",
        "122121112",
        "200121112",
        "012201112",
        "120201112",
        "201201112",
        "011202112",
        "122202112",
        "200202112",
        "000210112",
        "111210112",
        "222210112",
        "020211112",
        "101211112",
        "212211112",
        "002220112",
        "110220112",
        "221220112",
        "021222112",
        "102222112",
        "210222112",
        "111000120",
        "222000120",
        "020001120",
        "101001120",
        "010002120",
        "202002120",
        "002010120",
        "110010120",
        "100011120",
        "211011120",
        "021012120",
        "001020120",
        "220020120",
        "012021120",
        "122022120",
        "200022120",
        "011100120",
        "200100120",
        "001101120",
        "112101120",
        "120102120",
        "010110120",
        "121110120",
        "000111120",
        "222111120",
        "101112120",
        "212112120",
        "102120120",
        "110121120",
        "221121120",
        "022122120",
        "211122120",
        "022200120",
        "100200120",
        "210201120",
        "002202120",
        "221202120",
        "201210120",
        "011211120",
        "122211120",
        "112212120",
        "220212120",
        "020220120",
        "212220120",
        "121221120",
        "202221120",
        "000222120",
        "111222120",
        "111000121",
        "222000121",
        "020001121",
        "101001121",
        "010002121",
        "202002121",
        "002010121",
        "110010121",
        "100011121",
        "211011121",
        "021012121",
        "001020121",
        "220020121",
        "012021121",
        "122022121",
        "200022121",
        "011100121",
        "200100121",
        "001101121",
        "112101121",
        "120102121",
        "010110121",
        "121110121",
        "000111121",
        "222111121",
        "101112121",
        "212112121",
        "102120121",
        "110121121",
        "221121121",
        "022122121",
        "211122121",
        "022200121",
        "100200121",
        "210201121",
        "002202121",
        "221202121",
        "201210121",
        "011211121",
        "122211121",
        "112212121",
        "220212121",
        "020220121",
        "212220121",
        "121221121",
        "202221121",
        "000222121",
        "111222121",
        "111000122",
        "222000122",
        "020001122",
        "101001122",
        "010002122",
        "202002122",
        "002010122",
        "110010122",
        "100011122",
        "211011122",
        "021012122",
        "001020122",
        "220020122",
        "012021122",
        "122022122",
        "200022122",
        "011100122",
        "200100122",
        "001101122",
        "112101122",
        "120102122",
        "010110122",
        "121110122",
        "000111122",
        "222111122",
        "101112122",
        "212112122",
        "102120122",
        "110121122",
        "221121122",
        "022122122",
        "211122122",
        "022200122",
        "100200122",
        "210201122",
        "002202122",
        "221202122",
        "201210122",
        "011211122",
        "122211122",
        "112212122",
        "220212122",
        "020220122",
        "212220122",
        "121221122",
        "202221122",
        "000222122",
        "111222122",
        "000000200",
        "111000200",
        "122001200",
        "212001200",
        "001002200",
        "220002200",
        "212010200",
        "221010200",
        "010011200",
        "121011200",
        "102012200",
        "022020200",
        "100020200",
        "201021200",
        "011022200",
        "110022200",
        "122100200",
        "221100200",
        "100101200",
        "211101200",
        "012102200",
        "001110200",
        "112110200",
        "111111200",
        "222111200",
        "020112200",
        "200112200",
        "210120200",
        "002121200",
        "020121200",
        "121122200",
        "202122200",
        "010200200",
        "202200200",
        "021201200",
        "101202200",
        "110202200",
        "120210200",
        "002211200",
        "200211200",
        "022212200",
        "211212200",
        "011220200",
        "101220200",
        "112221200",
        "220221200",
        "000222200",
        "222222200",
        "111000201",
        "222000201",
        "020001201",
        "101001201",
        "010002201",
        "202002201",
        "002010201",
        "110010201",
        "100011201",
        "211011201",
        "021012201",
        "001020201",
        "220020201",
        "012021201",
        "122022201",
        "200022201",
        "011100201",
        "200100201",
        "001101201",
        "112101201",
        "120102201",
        "010110201",
        "121110201",
        "000111201",
        "222111201",
        "101112201",
        "212112201",
        "102120201",
        "110121201",
        "221121201",
        "022122201",
        "211122201",
        "022200201",
        "100200201",
        "210201201",
        "002202201",
        "221202201",
        "201210201",
        "011211201",
        "122211201",
        "112212201",
        "220212201",
        "020220201",
        "212220201",
        "121221201",
        "202221201",
        "000222201",
        "111222201",
        "021000202",
        "102000202",
        "210000202",
        "002001202",
        "110001202",
        "221001202",
        "011010202",
        "122010202",
        "200010202",
        "012012202",
        "120012202",
        "201012202",
        "000021202",
        "111021202",
        "222021202",
        "020022202",
        "101022202",
        "212022202",
        "020100202",
        "101100202",
        "212100202",
        "000102202",
        "111102202",
        "222102202",
        "021111202",
        "102111202",
        "210111202",
        "002112202",
        "110112202",
        "221112202",
        "012120202",
        "120120202",
        "201120202",
        "011121202",
        "122121202",
        "200121202",
        "012201202",
        "120201202",
        "201201202",
        "011202202",
        "122202202",
        "200202202",
        "000210202",
        "111210202",
        "222210202",
        "020211202",
        "101211202",
        "212211202",
        "002220202",
        "110220202",
        "221220202",
        "021222202",
        "102222202",
        "210222202",
        "021000210",
        "102000210",
        "210000210",
        "002001210",
        "110001210",
        "221001210",
        "011010210",
        "122010210",
        "200010210",
        "012012210",
        "120012210",
        "201012210",
        "000021210",
        "111021210",
        "222021210",
        "020022210",
        "101022210",
        "212022210",
        "020100210",
        "101100210",
        "212100210",
        "000102210",
        "111102210",
        "222102210",
        "021111210",
        "102111210",
        "210111210",
        "002112210",
        "110112210",
        "221112210",
        "012120210",
        "120120210",
        "201120210",
        "011121210",
        "122121210",
        "200121210",
        "012201210",
        "120201210",
        "201201210",
        "011202210",
        "122202210",
        "200202210",
        "000210210",
        "111210210",
        "222210210",
        "020211210",
        "101211210",
        "212211210",
        "002220210",
        "110220210",
        "221220210",
        "021222210",
        "102222210",
        "210222210",
        "111000211",
        "222000211",
        "020001211",
        "101001211",
        "010002211",
        "202002211",
        "002010211",
        "110010211",
        "100011211",
        "211011211",
        "021012211",
        "001020211",
        "220020211",
        "012021211",
        "122022211",
        "200022211",
        "011100211",
        "200100211",
        "001101211",
        "112101211",
        "120102211",
        "010110211",
        "121110211",
        "000111211",
        "222111211",
        "101112211",
        "212112211",
        "102120211",
        "110121211",
        "221121211",
        "022122211",
        "211122211",
        "022200211",
        "100200211",
        "210201211",
        "002202211",
        "221202211",
        "201210211",
        "011211211",
        "122211211",
        "112212211",
        "220212211",
        "020220211",
        "212220211",
        "121221211",
        "202221211",
        "000222211",
        "111222211",
        "012000212",
        "120000212",
        "201000212",
        "022002212",
        "100002212",
        "211002212",
        "001011212",
        "112011212",
        "220011212",
        "000012212",
        "111012212",
        "222012212",
        "010020212",
        "121020212",
        "202020212",
        "021021212",
        "102021212",
        "210021212",
        "010101212",
        "121101212",
        "202101212",
        "021102212",
        "102102212",
        "210102212",
        "022110212",
        "100110212",
        "211110212",
        "012111212",
        "120111212",
        "201111212",
        "000120212",
        "111120212",
        "222120212",
        "001122212",
        "112122212",
        "220122212",
        "001200212",
        "112200212",
        "220200212",
        "000201212",
        "111201212",
        "222201212",
        "021210212",
        "102210212",
        "210210212",
        "010212212",
        "121212212",
        "202212212",
        "022221212",
        "100221212",
        "211221212",
        "012222212",
        "120222212",
        "201222212",
        "012000220",
        "120000220",
        "201000220",
        "022002220",
        "100002220",
        "211002220",
        "001011220",
        "112011220",
        "220011220",
        "000012220",
        "111012220",
        "222012220",
        "010020220",
        "121020220",
        "202020220",
        "021021220",
        "102021220",
        "210021220",
        "010101220",
        "121101220",
        "202101220",
        "021102220",
        "102102220",
        "210102220",
        "022110220",
        "100110220",
        "211110220",
        "012111220",
        "120111220",
        "201111220",
        "000120220",
        "111120220",
        "222120220",
        "001122220",
        "112122220",
        "220122220",
        "001200220",
        "112200220",
        "220200220",
        "000201220",
        "111201220",
        "222201220",
        "021210220",
        "102210220",
        "210210220",
        "010212220",
        "121212220",
        "202212220",
        "022221220",
        "100221220",
        "211221220",
        "012222220",
        "120222220",
        "201222220",
        "000000221",
        "222000221",
        "011001221",
        "200001221",
        "112002221",
        "121002221",
        "020010221",
        "101010221",
        "022011221",
        "202011221",
        "210012221",
        "112020221",
        "211020221",
        "120021221",
        "002022221",
        "221022221",
        "002100221",
        "110100221",
        "022101221",
        "220101221",
        "201102221",
        "202110221",
        "220110221",
        "000111221",
        "111111221",
        "011112221",
        "122112221",
        "021120221",
        "101121221",
        "212121221",
        "010122221",
        "100122221",
        "121200221",
        "211200221",
        "102201221",
        "020202221",
        "212202221",
        "012210221",
        "110211221",
        "221211221",
        "001212221",
        "100212221",
        "122220221",
        "200220221",
        "001221221",
        "010221221",
        "111222221",
        "222222221",
        "000000222",
        "111000222",
        "122001222",
        "212001222",
        "001002222",
        "220002222",
        "212010222",
        "221010222",
        "010011222",
        "121011222",
        "102012222",
        "022020222",
        "100020222",
        "201021222",
        "011022222",
        "110022222",
        "122100222",
        "221100222",
        "100101222",
        "211101222",
        "012102222",
        "001110222",
        "112110222",
        "111111222",
        "222111222",
        "020112222",
        "200112222",
        "210120222",
        "002121222",
        "020121222",
        "121122222",
        "202122222",
        "010200222",
        "202200222",
        "021201222",
        "101202222",
        "110202222",
        "120210222",
        "002211222",
        "200211222",
        "022212222",
        "211212222",
        "011220222",
        "101220222",
        "112221222",
        "220221222",
        "000222222",
        "222222222"
    ];

    public R_10_0_153: string[] = ["2111012222",
        "2222212121",
        "2222210102",
        "1221000000",
        "1211211001",
        "1211210102",
        "0102100111",
        "0010200000",
        "2121102202",
        "1000112102",
        "0201110010",
        "1112201022",
        "0102102100",
        "2020022100",
        "1100221021",
        "0102100002",
        "0122010222",
        "0010202022",
        "0210121210",
        "0122011121",
        "0122012211",
        "1000110110",
        "1211210020",
        "0001222001",
        "2202000100",
        "0210121101",
        "0122012102",
        "1221000221",
        "2202001111",
        "2121101221",
        "2222210020",
        "2222212200",
        "2202002010",
        "1012122120",
        "2111011102",
        "0001221102",
        "0010202101",
        "0010200221",
        "1112202000",
        "1000111012",
        "1100221100",
        "0102102212",
        "1221001120",
        "0210120011",
        "1012120210",
        "1112200011",
        "1221001011",
        "1000112020",
        "1221001202",
        "0122011012",
        "0001221211",
        "1000111200",
        "0001221020",
        "1100221212",
        "0001220200",
        "2121102011",
        "1211210211",
        "2222211110",
        "0001220121",
        "0102101201",
        "0201112220",
        "0210122112",
        "2121100210",
        "1211212200",
        "1012122202",
        "1100220122",
        "0201111212",
        "1112201101",
        "1100220010",
        "2202000212",
        "1112201210",
        "1221002101",
        "1012121000",
        "0210122000",
        "0210120120",
        "2111012110",
        "2111011020",
        "1012120101",
        "1000110001",
        "0010202210",
        "2121100022",
        "2020021201",
        "2020021122",
        "0010201011",
        "0001222110",
        "2020020002",
        "1100220201",
        "0201111100",
        "0122010110",
        "0102101122",
        "1211211110",
        "1112200202",
        "1112202112",
        "0201111021",
        "1000112211",
        "2202001002",
        "1000110222",
        "2111011211",
        "1221002210",
        "2020020111",
        "2202001220",
        "0122012020",
        "2121101112",
        "2111012001",
        "2202002122",
        "0102100220",
        "1221002022",
        "1012122011",
        "0010201202",
        "1211211222",
        "2111010200",
        "1012121221",
        "1012121112",
        "0210121022",
        "2202002201",
        "2222211222",
        "1100222111",
        "0122011200",
        "2111010012",
        "0201110122",
        "0201112111",
        "1112202221",
        "2020022212",
        "0122010001",
        "1012120022",
        "0201110201",
        "2202000021",
        "0210120202",
        "0201112002",
        "1100222002",
        "1221000112",
        "2222210211",
        "0001220012",
        "2020020220",
        "0001222222",
        "2222211001",
        "2121101000",
        "2222212012",
        "2121102120",
        "2020021010",
        "2111010121",
        "1112200120",
        "1211212121",
        "0102101010",
        "1100222220",
        "2020022021",
        "0010201120",
        "1000111121",
        "1211212012",
        "0010200112",
        "2121100101",
        "0210122221",
        "0102102021"
    ];

    public R_10_0_567: string[] = ["0000000000",
        "0000000111",
        "0000000222",
        "0000012001",
        "0000012112",
        "0000012220",
        "0000021002",
        "0000021110",
        "0000021221",
        "0000101001",
        "0000101112",
        "0000101220",
        "0000112011",
        "0000112122",
        "0000112200",
        "0000202002",
        "0000202110",
        "0000202221",
        "0000221010",
        "0000221121",
        "0000221202",
        "0001002022",
        "0001002100",
        "0001002211",
        "0001010020",
        "0001010101",
        "0001010212",
        "0001102020",
        "0001102101",
        "0001102212",
        "0001111000",
        "0001111111",
        "0001111222",
        "0001120010",
        "0001120121",
        "0001120202",
        "0001212010",
        "0001212121",
        "0001212202",
        "0001220002",
        "0001220110",
        "0001220221",
        "0002001020",
        "0002001101",
        "0002001212",
        "0002020022",
        "0002020100",
        "0002020211",
        "0002110001",
        "0002110112",
        "0002110220",
        "0002121011",
        "0002121122",
        "0002121200",
        "0002201022",
        "0002201100",
        "0002201211",
        "0002210011",
        "0002210122",
        "0002210200",
        "0002222000",
        "0002222111",
        "0002222222",
        "0120000012",
        "0120000120",
        "0120000201",
        "0120011010",
        "0120011121",
        "0120011202",
        "0120022011",
        "0120022122",
        "0120022200",
        "0120120010",
        "0120120121",
        "0120120202",
        "0120121020",
        "0120121101",
        "0120121212",
        "0120210011",
        "0120210122",
        "0120210200",
        "0120212022",
        "0120212100",
        "0120212211",
        "0121020001",
        "0121020112",
        "0121020220",
        "0121021002",
        "0121021110",
        "0121021221",
        "0121100002",
        "0121100110",
        "0121100221",
        "0121111012",
        "0121111120",
        "0121111201",
        "0121122022",
        "0121122100",
        "0121122211",
        "0121201022",
        "0121201100",
        "0121201211",
        "0121202011",
        "0121202122",
        "0121202200",
        "0122010002",
        "0122010110",
        "0122010221",
        "0122012001",
        "0122012112",
        "0122012220",
        "0122101010",
        "0122101121",
        "0122101202",
        "0122102020",
        "0122102101",
        "0122102212",
        "0122200001",
        "0122200112",
        "0122200220",
        "0122211020",
        "0122211101",
        "0122211212",
        "0122222012",
        "0122222120",
        "0122222201",
        "0210000021",
        "0210000102",
        "0210000210",
        "0210102020",
        "0210102101",
        "0210102212",
        "0210110022",
        "0210110100",
        "0210110211",
        "0210122001",
        "0210122112",
        "0210122220",
        "0210201022",
        "0210201100",
        "0210201211",
        "0210211002",
        "0210211110",
        "0210211221",
        "0210220020",
        "0210220101",
        "0210220212",
        "0211001011",
        "0211001122",
        "0211001200",
        "0211012001",
        "0211012112",
        "0211012220",
        "0211022020",
        "0211022101",
        "0211022212",
        "0211111021",
        "0211111102",
        "0211111210",
        "0211200010",
        "0211200121",
        "0211200202",
        "0211210011",
        "0211210122",
        "0211210200",
        "0211221001",
        "0211221112",
        "0211221220",
        "0212002010",
        "0212002121",
        "0212002202",
        "0212011022",
        "0212011100",
        "0212011211",
        "0212021002",
        "0212021110",
        "0212021221",
        "0212100011",
        "0212100122",
        "0212100200",
        "0212112002",
        "0212112110",
        "0212112221",
        "0212120010",
        "0212120121",
        "0212120202",
        "0212222021",
        "0212222102",
        "0212222210",
        "1020000021",
        "1020000102",
        "1020000210",
        "1020102020",
        "1020102101",
        "1020102212",
        "1020110022",
        "1020110100",
        "1020110211",
        "1020122001",
        "1020122112",
        "1020122220",
        "1020201022",
        "1020201100",
        "1020201211",
        "1020211002",
        "1020211110",
        "1020211221",
        "1020220020",
        "1020220101",
        "1020220212",
        "1021001011",
        "1021001122",
        "1021001200",
        "1021012001",
        "1021012112",
        "1021012220",
        "1021022020",
        "1021022101",
        "1021022212",
        "1021111021",
        "1021111102",
        "1021111210",
        "1021200010",
        "1021200121",
        "1021200202",
        "1021210011",
        "1021210122",
        "1021210200",
        "1021221001",
        "1021221112",
        "1021221220",
        "1022002010",
        "1022002121",
        "1022002202",
        "1022011022",
        "1022011100",
        "1022011211",
        "1022021002",
        "1022021110",
        "1022021221",
        "1022100011",
        "1022100122",
        "1022100200",
        "1022112002",
        "1022112110",
        "1022112221",
        "1022120010",
        "1022120121",
        "1022120202",
        "1022222021",
        "1022222102",
        "1022222210",
        "1110000000",
        "1110000111",
        "1110000222",
        "1110012001",
        "1110012112",
        "1110012220",
        "1110021002",
        "1110021110",
        "1110021221",
        "1110101001",
        "1110101112",
        "1110101220",
        "1110112011",
        "1110112122",
        "1110112200",
        "1110202002",
        "1110202110",
        "1110202221",
        "1110221010",
        "1110221121",
        "1110221202",
        "1111002022",
        "1111002100",
        "1111002211",
        "1111010020",
        "1111010101",
        "1111010212",
        "1111102020",
        "1111102101",
        "1111102212",
        "1111111000",
        "1111111111",
        "1111111222",
        "1111120010",
        "1111120121",
        "1111120202",
        "1111212010",
        "1111212121",
        "1111212202",
        "1111220002",
        "1111220110",
        "1111220221",
        "1112001020",
        "1112001101",
        "1112001212",
        "1112020022",
        "1112020100",
        "1112020211",
        "1112110001",
        "1112110112",
        "1112110220",
        "1112121011",
        "1112121122",
        "1112121200",
        "1112201022",
        "1112201100",
        "1112201211",
        "1112210011",
        "1112210122",
        "1112210200",
        "1112222000",
        "1112222111",
        "1112222222",
        "1200000012",
        "1200000120",
        "1200000201",
        "1200011010",
        "1200011121",
        "1200011202",
        "1200022011",
        "1200022122",
        "1200022200",
        "1200120010",
        "1200120121",
        "1200120202",
        "1200121020",
        "1200121101",
        "1200121212",
        "1200210011",
        "1200210122",
        "1200210200",
        "1200212022",
        "1200212100",
        "1200212211",
        "1201020001",
        "1201020112",
        "1201020220",
        "1201021002",
        "1201021110",
        "1201021221",
        "1201100002",
        "1201100110",
        "1201100221",
        "1201111012",
        "1201111120",
        "1201111201",
        "1201122022",
        "1201122100",
        "1201122211",
        "1201201022",
        "1201201100",
        "1201201211",
        "1201202011",
        "1201202122",
        "1201202200",
        "1202010002",
        "1202010110",
        "1202010221",
        "1202012001",
        "1202012112",
        "1202012220",
        "1202101010",
        "1202101121",
        "1202101202",
        "1202102020",
        "1202102101",
        "1202102212",
        "1202200001",
        "1202200112",
        "1202200220",
        "1202211020",
        "1202211101",
        "1202211212",
        "1202222012",
        "1202222120",
        "1202222201",
        "2010000012",
        "2010000120",
        "2010000201",
        "2010011010",
        "2010011121",
        "2010011202",
        "2010022011",
        "2010022122",
        "2010022200",
        "2010120010",
        "2010120121",
        "2010120202",
        "2010121020",
        "2010121101",
        "2010121212",
        "2010210011",
        "2010210122",
        "2010210200",
        "2010212022",
        "2010212100",
        "2010212211",
        "2011020001",
        "2011020112",
        "2011020220",
        "2011021002",
        "2011021110",
        "2011021221",
        "2011100002",
        "2011100110",
        "2011100221",
        "2011111012",
        "2011111120",
        "2011111201",
        "2011122022",
        "2011122100",
        "2011122211",
        "2011201022",
        "2011201100",
        "2011201211",
        "2011202011",
        "2011202122",
        "2011202200",
        "2012010002",
        "2012010110",
        "2012010221",
        "2012012001",
        "2012012112",
        "2012012220",
        "2012101010",
        "2012101121",
        "2012101202",
        "2012102020",
        "2012102101",
        "2012102212",
        "2012200001",
        "2012200112",
        "2012200220",
        "2012211020",
        "2012211101",
        "2012211212",
        "2012222012",
        "2012222120",
        "2012222201",
        "2100000021",
        "2100000102",
        "2100000210",
        "2100102020",
        "2100102101",
        "2100102212",
        "2100110022",
        "2100110100",
        "2100110211",
        "2100122001",
        "2100122112",
        "2100122220",
        "2100201022",
        "2100201100",
        "2100201211",
        "2100211002",
        "2100211110",
        "2100211221",
        "2100220020",
        "2100220101",
        "2100220212",
        "2101001011",
        "2101001122",
        "2101001200",
        "2101012001",
        "2101012112",
        "2101012220",
        "2101022020",
        "2101022101",
        "2101022212",
        "2101111021",
        "2101111102",
        "2101111210",
        "2101200010",
        "2101200121",
        "2101200202",
        "2101210011",
        "2101210122",
        "2101210200",
        "2101221001",
        "2101221112",
        "2101221220",
        "2102002010",
        "2102002121",
        "2102002202",
        "2102011022",
        "2102011100",
        "2102011211",
        "2102021002",
        "2102021110",
        "2102021221",
        "2102100011",
        "2102100122",
        "2102100200",
        "2102112002",
        "2102112110",
        "2102112221",
        "2102120010",
        "2102120121",
        "2102120202",
        "2102222021",
        "2102222102",
        "2102222210",
        "2220000000",
        "2220000111",
        "2220000222",
        "2220012001",
        "2220012112",
        "2220012220",
        "2220021002",
        "2220021110",
        "2220021221",
        "2220101001",
        "2220101112",
        "2220101220",
        "2220112011",
        "2220112122",
        "2220112200",
        "2220202002",
        "2220202110",
        "2220202221",
        "2220221010",
        "2220221121",
        "2220221202",
        "2221002022",
        "2221002100",
        "2221002211",
        "2221010020",
        "2221010101",
        "2221010212",
        "2221102020",
        "2221102101",
        "2221102212",
        "2221111000",
        "2221111111",
        "2221111222",
        "2221120010",
        "2221120121",
        "2221120202",
        "2221212010",
        "2221212121",
        "2221212202",
        "2221220002",
        "2221220110",
        "2221220221",
        "2222001020",
        "2222001101",
        "2222001212",
        "2222020022",
        "2222020100",
        "2222020211",
        "2222110001",
        "2222110112",
        "2222110220",
        "2222121011",
        "2222121122",
        "2222121200",
        "2222201022",
        "2222201100",
        "2222201211",
        "2222210011",
        "2222210122",
        "2222210200",
        "2222222000",
        "2222222111",
        "2222222222"
    ];

    public R_11_0_729: string[] = ["00000000000",
        "00000111111",
        "00000222222",
        "00001012210",
        "00001120021",
        "00001201102",
        "00002021120",
        "00002102201",
        "00002210012",
        "00010022101",
        "00010100212",
        "00010211020",
        "00011001011",
        "00011112122",
        "00011220200",
        "00012010221",
        "00012121002",
        "00012202110",
        "00020011202",
        "00020122010",
        "00020200121",
        "00021020112",
        "00021101220",
        "00021212001",
        "00022002022",
        "00022110100",
        "00022221211",
        "00100001221",
        "00100112002",
        "00100220110",
        "00101010101",
        "00101121212",
        "00101202020",
        "00102022011",
        "00102100122",
        "00102211200",
        "00110020022",
        "00110101100",
        "00110212211",
        "00111002202",
        "00111110010",
        "00111221121",
        "00112011112",
        "00112122220",
        "00112200001",
        "00120012120",
        "00120120201",
        "00120201012",
        "00121021000",
        "00121102111",
        "00121210222",
        "00122000210",
        "00122111021",
        "00122222102",
        "00200002112",
        "00200110220",
        "00200221001",
        "00201011022",
        "00201122100",
        "00201200211",
        "00202020202",
        "00202101010",
        "00202212121",
        "00210021210",
        "00210102021",
        "00210210102",
        "00211000120",
        "00211111201",
        "00211222012",
        "00212012000",
        "00212120111",
        "00212201222",
        "00220010011",
        "00220121122",
        "00220202200",
        "00221022221",
        "00221100002",
        "00221211110",
        "00222001101",
        "00222112212",
        "00222220020",
        "01000012021",
        "01000120102",
        "01000201210",
        "01001021201",
        "01001102012",
        "01001210120",
        "01002000111",
        "01002111222",
        "01002222000",
        "01010001122",
        "01010112200",
        "01010220011",
        "01011010002",
        "01011121110",
        "01011202221",
        "01012022212",
        "01012100020",
        "01012211101",
        "01020020220",
        "01020101001",
        "01020212112",
        "01021002100",
        "01021110211",
        "01021221022",
        "01022011010",
        "01022122121",
        "01022200202",
        "01100010212",
        "01100121020",
        "01100202101",
        "01101022122",
        "01101100200",
        "01101211011",
        "01102001002",
        "01102112110",
        "01102220221",
        "01110002010",
        "01110110121",
        "01110221202",
        "01111011220",
        "01111122001",
        "01111200112",
        "01112020100",
        "01112101211",
        "01112212022",
        "01120021111",
        "01120102222",
        "01120210000",
        "01121000021",
        "01121111102",
        "01121222210",
        "01122012201",
        "01122120012",
        "01122201120",
        "01200011100",
        "01200122211",
        "01200200022",
        "01201020010",
        "01201101121",
        "01201212202",
        "01202002220",
        "01202110001",
        "01202221112",
        "01210000201",
        "01210111012",
        "01210222120",
        "01211012111",
        "01211120222",
        "01211201000",
        "01212021021",
        "01212102102",
        "01212210210",
        "01220022002",
        "01220100110",
        "01220211221",
        "01221001212",
        "01221112020",
        "01221220101",
        "01222010122",
        "01222121200",
        "01222202011",
        "02000021012",
        "02000102120",
        "02000210201",
        "02001000222",
        "02001111000",
        "02001222111",
        "02002012102",
        "02002120210",
        "02002201021",
        "02010010110",
        "02010121221",
        "02010202002",
        "02011022020",
        "02011100101",
        "02011211212",
        "02012001200",
        "02012112011",
        "02012220122",
        "02020002211",
        "02020110022",
        "02020221100",
        "02021011121",
        "02021122202",
        "02021200010",
        "02022020001",
        "02022101112",
        "02022212220",
        "02100022200",
        "02100100011",
        "02100211122",
        "02101001110",
        "02101112221",
        "02101220002",
        "02102010020",
        "02102121101",
        "02102202212",
        "02110011001",
        "02110122112",
        "02110200220",
        "02111020211",
        "02111101022",
        "02111212100",
        "02112002121",
        "02112110202",
        "02112221010",
        "02120000102",
        "02120111210",
        "02120222021",
        "02121012012",
        "02121120120",
        "02121201201",
        "02122021222",
        "02122102000",
        "02122210111",
        "02200020121",
        "02200101202",
        "02200212010",
        "02201002001",
        "02201110112",
        "02201221220",
        "02202011211",
        "02202122022",
        "02202200100",
        "02210012222",
        "02210120000",
        "02210201111",
        "02211021102",
        "02211102210",
        "02211210021",
        "02212000012",
        "02212111120",
        "02212222201",
        "02220001020",
        "02220112101",
        "02220220212",
        "02221010200",
        "02221121011",
        "02221202122",
        "02222022110",
        "02222100221",
        "02222211002",
        "10000020211",
        "10000101022",
        "10000212100",
        "10001002121",
        "10001110202",
        "10001221010",
        "10002011001",
        "10002122112",
        "10002200220",
        "10010012012",
        "10010120120",
        "10010201201",
        "10011021222",
        "10011102000",
        "10011210111",
        "10012000102",
        "10012111210",
        "10012222021",
        "10020001110",
        "10020112221",
        "10020220002",
        "10021010020",
        "10021121101",
        "10021202212",
        "10022022200",
        "10022100011",
        "10022211122",
        "10100021102",
        "10100102210",
        "10100210021",
        "10101000012",
        "10101111120",
        "10101222201",
        "10102012222",
        "10102120000",
        "10102201111",
        "10110010200",
        "10110121011",
        "10110202122",
        "10111022110",
        "10111100221",
        "10111211002",
        "10112001020",
        "10112112101",
        "10112220212",
        "10120002001",
        "10120110112",
        "10120221220",
        "10121011211",
        "10121122022",
        "10121200100",
        "10122020121",
        "10122101202",
        "10122212010",
        "10200022020",
        "10200100101",
        "10200211212",
        "10201001200",
        "10201112011",
        "10201220122",
        "10202010110",
        "10202121221",
        "10202202002",
        "10210011121",
        "10210122202",
        "10210200010",
        "10211020001",
        "10211101112",
        "10211212220",
        "10212002211",
        "10212110022",
        "10212221100",
        "10220000222",
        "10220111000",
        "10220222111",
        "10221012102",
        "10221120210",
        "10221201021",
        "10222021012",
        "10222102120",
        "10222210201",
        "11000002202",
        "11000110010",
        "11000221121",
        "11001011112",
        "11001122220",
        "11001200001",
        "11002020022",
        "11002101100",
        "11002212211",
        "11010021000",
        "11010102111",
        "11010210222",
        "11011000210",
        "11011111021",
        "11011222102",
        "11012012120",
        "11012120201",
        "11012201012",
        "11020010101",
        "11020121212",
        "11020202020",
        "11021022011",
        "11021100122",
        "11021211200",
        "11022001221",
        "11022112002",
        "11022220110",
        "11100000120",
        "11100111201",
        "11100222012",
        "11101012000",
        "11101120111",
        "11101201222",
        "11102021210",
        "11102102021",
        "11102210102",
        "11110022221",
        "11110100002",
        "11110211110",
        "11111001101",
        "11111112212",
        "11111220020",
        "11112010011",
        "11112121122",
        "11112202200",
        "11120011022",
        "11120122100",
        "11120200211",
        "11121020202",
        "11121101010",
        "11121212121",
        "11122002112",
        "11122110220",
        "11122221001",
        "11200001011",
        "11200112122",
        "11200220200",
        "11201010221",
        "11201121002",
        "11201202110",
        "11202022101",
        "11202100212",
        "11202211020",
        "11210020112",
        "11210101220",
        "11210212001",
        "11211002022",
        "11211110100",
        "11211221211",
        "11212011202",
        "11212122010",
        "11212200121",
        "11220012210",
        "11220120021",
        "11220201102",
        "11221021120",
        "11221102201",
        "11221210012",
        "11222000000",
        "11222111111",
        "11222222222",
        "12000011220",
        "12000122001",
        "12000200112",
        "12001020100",
        "12001101211",
        "12001212022",
        "12002002010",
        "12002110121",
        "12002221202",
        "12010000021",
        "12010111102",
        "12010222210",
        "12011012201",
        "12011120012",
        "12011201120",
        "12012021111",
        "12012102222",
        "12012210000",
        "12020022122",
        "12020100200",
        "12020211011",
        "12021001002",
        "12021112110",
        "12021220221",
        "12022010212",
        "12022121020",
        "12022202101",
        "12100012111",
        "12100120222",
        "12100201000",
        "12101021021",
        "12101102102",
        "12101210210",
        "12102000201",
        "12102111012",
        "12102222120",
        "12110001212",
        "12110112020",
        "12110220101",
        "12111010122",
        "12111121200",
        "12111202011",
        "12112022002",
        "12112100110",
        "12112211221",
        "12120020010",
        "12120101121",
        "12120212202",
        "12121002220",
        "12121110001",
        "12121221112",
        "12122011100",
        "12122122211",
        "12122200022",
        "12200010002",
        "12200121110",
        "12200202221",
        "12201022212",
        "12201100020",
        "12201211101",
        "12202001122",
        "12202112200",
        "12202220011",
        "12210002100",
        "12210110211",
        "12210221022",
        "12211011010",
        "12211122121",
        "12211200202",
        "12212020220",
        "12212101001",
        "12212212112",
        "12220021201",
        "12220102012",
        "12220210120",
        "12221000111",
        "12221111222",
        "12221222000",
        "12222012021",
        "12222120102",
        "12222201210",
        "20000010122",
        "20000121200",
        "20000202011",
        "20001022002",
        "20001100110",
        "20001211221",
        "20002001212",
        "20002112020",
        "20002220101",
        "20010002220",
        "20010110001",
        "20010221112",
        "20011011100",
        "20011122211",
        "20011200022",
        "20012020010",
        "20012101121",
        "20012212202",
        "20020021021",
        "20020102102",
        "20020210210",
        "20021000201",
        "20021111012",
        "20021222120",
        "20022012111",
        "20022120222",
        "20022201000",
        "20100011010",
        "20100122121",
        "20100200202",
        "20101020220",
        "20101101001",
        "20101212112",
        "20102002100",
        "20102110211",
        "20102221022",
        "20110000111",
        "20110111222",
        "20110222000",
        "20111012021",
        "20111120102",
        "20111201210",
        "20112021201",
        "20112102012",
        "20112210120",
        "20120022212",
        "20120100020",
        "20120211101",
        "20121001122",
        "20121112200",
        "20121220011",
        "20122010002",
        "20122121110",
        "20122202221",
        "20200012201",
        "20200120012",
        "20200201120",
        "20201021111",
        "20201102222",
        "20201210000",
        "20202000021",
        "20202111102",
        "20202222210",
        "20210001002",
        "20210112110",
        "20210220221",
        "20211010212",
        "20211121020",
        "20211202101",
        "20212022122",
        "20212100200",
        "20212211011",
        "20220020100",
        "20220101211",
        "20220212022",
        "20221002010",
        "20221110121",
        "20221221202",
        "20222011220",
        "20222122001",
        "20222200112",
        "21000022110",
        "21000100221",
        "21000211002",
        "21001001020",
        "21001112101",
        "21001220212",
        "21002010200",
        "21002121011",
        "21002202122",
        "21010011211",
        "21010122022",
        "21010200100",
        "21011020121",
        "21011101202",
        "21011212010",
        "21012002001",
        "21012110112",
        "21012221220",
        "21020000012",
        "21020111120",
        "21020222201",
        "21021012222",
        "21021120000",
        "21021201111",
        "21022021102",
        "21022102210",
        "21022210021",
        "21100020001",
        "21100101112",
        "21100212220",
        "21101002211",
        "21101110022",
        "21101221100",
        "21102011121",
        "21102122202",
        "21102200010",
        "21110012102",
        "21110120210",
        "21110201021",
        "21111021012",
        "21111102120",
        "21111210201",
        "21112000222",
        "21112111000",
        "21112222111",
        "21120001200",
        "21120112011",
        "21120220122",
        "21121010110",
        "21121121221",
        "21121202002",
        "21122022020",
        "21122100101",
        "21122211212",
        "21200021222",
        "21200102000",
        "21200210111",
        "21201000102",
        "21201111210",
        "21201222021",
        "21202012012",
        "21202120120",
        "21202201201",
        "21210010020",
        "21210121101",
        "21210202212",
        "21211022200",
        "21211100011",
        "21211211122",
        "21212001110",
        "21212112221",
        "21212220002",
        "21220002121",
        "21220110202",
        "21220221010",
        "21221011001",
        "21221122112",
        "21221200220",
        "21222020211",
        "21222101022",
        "21222212100",
        "22000001101",
        "22000112212",
        "22000220020",
        "22001010011",
        "22001121122",
        "22001202200",
        "22002022221",
        "22002100002",
        "22002211110",
        "22010020202",
        "22010101010",
        "22010212121",
        "22011002112",
        "22011110220",
        "22011221001",
        "22012011022",
        "22012122100",
        "22012200211",
        "22020012000",
        "22020120111",
        "22020201222",
        "22021021210",
        "22021102021",
        "22021210102",
        "22022000120",
        "22022111201",
        "22022222012",
        "22100002022",
        "22100110100",
        "22100221211",
        "22101011202",
        "22101122010",
        "22101200121",
        "22102020112",
        "22102101220",
        "22102212001",
        "22110021120",
        "22110102201",
        "22110210012",
        "22111000000",
        "22111111111",
        "22111222222",
        "22112012210",
        "22112120021",
        "22112201102",
        "22120010221",
        "22120121002",
        "22120202110",
        "22121022101",
        "22121100212",
        "22121211020",
        "22122001011",
        "22122112122",
        "22122220200",
        "22200000210",
        "22200111021",
        "22200222102",
        "22201012120",
        "22201120201",
        "22201201012",
        "22202021000",
        "22202102111",
        "22202210222",
        "22210022011",
        "22210100122",
        "22210211200",
        "22211001221",
        "22211112002",
        "22211220110",
        "22212010101",
        "22212121212",
        "22212202020",
        "22220011112",
        "22220122220",
        "22220200001",
        "22221020022",
        "22221101100",
        "22221212211",
        "22222002202",
        "22222110010",
        "22222221121"
    ];
}

