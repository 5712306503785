export const EgnaraderLogo = () => (
    <svg width="500" height="100" data-id="lg_mJxLgfNYsJOqP5JBTD" data-version="1" viewBox="0 0 1500 298">
        <g data-padding="20">
            <g transform="translate(10 5.321)scale(.96429)">
                <rect width="560" height="177.41" x="86.907" y="-339.98" fill="none" rx="0" ry="0" transform="translate(400.5 340.16)" />
                <path fill="#fbfbfb" d="M494.477 137.705v-109.9h47.1v15.7h-29.83v29.05h23.7v15.7h-23.7v33.75h29.83v15.7Zm81.48 1.57q-12.56 0-19.16-7.14-6.59-7.14-6.59-20.49v-57.78q0-13.34 6.59-20.48 6.6-7.15 19.16-7.15t19.15 7.15q6.6 7.14 6.6 20.48v9.42h-16.33v-10.51q0-10.84-8.95-10.84t-8.95 10.84v60.13q0 10.67 8.95 10.67t8.95-10.67v-21.51h-8.64v-15.7h24.97v35.95q0 13.35-6.6 20.49-6.59 7.14-19.15 7.14m36.9-1.57v-109.9h21.67l16.8 65.79h.31v-65.79h15.39v109.9h-17.74l-20.73-80.22h-.31v80.22Zm62.49 0 17.9-109.9h23.39l17.9 109.9h-17.27l-3.14-21.82v.31h-19.63l-3.14 21.51Zm21.19-36.42h15.55l-7.7-54.32h-.31Zm46.31 36.42v-109.9h25.59q13.35 0 19.47 6.2 6.12 6.21 6.12 19.08v6.75q0 17.11-11.3 21.67v.31q6.28 1.88 8.87 7.69t2.59 15.55v19.31q0 4.71.31 7.61.32 2.91 1.57 5.73h-17.58q-.94-2.67-1.26-5.02-.31-2.36-.31-8.48v-20.1q0-7.53-2.43-10.51-2.44-2.99-8.4-2.99h-5.97v47.1Zm17.27-62.8h6.28q5.18 0 7.77-2.67t2.59-8.95v-8.47q0-5.97-2.12-8.64t-6.67-2.67h-7.85Zm41.46 62.8 17.9-109.9h23.39l17.9 109.9h-17.27l-3.14-21.82v.31h-19.63l-3.14 21.51Zm21.19-36.42h15.55l-7.7-54.32h-.31Zm46.31 36.42v-109.9h26.37q12.88 0 19.31 6.91 6.44 6.91 6.44 20.25v55.58q0 13.35-6.44 20.25-6.43 6.91-19.31 6.91Zm17.27-15.7h8.79q4.24 0 6.52-2.51 2.27-2.51 2.27-8.16v-57.15q0-5.65-2.27-8.17-2.28-2.51-6.52-2.51h-8.79Zm46.47 15.7v-109.9h47.1v15.7h-29.83v29.05h23.71v15.7h-23.71v33.75h29.83v15.7Zm56.99 0v-109.9h25.59q13.35 0 19.47 6.2 6.12 6.21 6.12 19.08v6.75q0 17.11-11.3 21.67v.31q6.28 1.88 8.87 7.69t2.59 15.55v19.31q0 4.71.31 7.61.32 2.91 1.57 5.73h-17.58q-.94-2.67-1.26-5.02-.31-2.36-.31-8.48v-20.1q0-7.53-2.43-10.51-2.44-2.99-8.4-2.99h-5.97v47.1Zm17.27-62.8h6.28q5.18 0 7.77-2.67t2.59-8.95v-8.47q0-5.97-2.12-8.64t-6.67-2.67h-7.85Z" paint-order="stroke" />
                <rect width="560" height="177.41" x="86.907" y="162.57" fill="none" rx="0" ry="0" transform="matrix(1 0 0 -1 400.5 460.39)" />
                <path fill="#2f76db" d="M494.477 160.295v109.9h47.1v-15.7h-29.83v-29.05h23.7v-15.7h-23.7v-33.75h29.83v-15.7Zm81.48-1.57q-12.56 0-19.16 7.14-6.59 7.14-6.59 20.49v57.77q0 13.35 6.59 20.49 6.6 7.15 19.16 7.15t19.15-7.15q6.6-7.14 6.6-20.49v-9.42h-16.33v10.52q0 10.84-8.95 10.84t-8.95-10.84v-60.13q0-10.67 8.95-10.67t8.95 10.67v21.51h-8.64v15.7h24.97v-35.95q0-13.35-6.6-20.49-6.59-7.14-19.15-7.14m36.9 1.57v109.9h21.67l16.8-65.79h.31v65.79h15.39v-109.9h-17.74l-20.73 80.22h-.31v-80.22Zm62.49 0 17.9 109.9h23.39l17.9-109.9h-17.27l-3.14 21.82v-.32h-19.63l-3.14-21.5Zm21.19 36.42h15.55l-7.7 54.32h-.31Zm46.31-36.42v109.9h25.59q13.35 0 19.47-6.21 6.12-6.2 6.12-19.07v-6.75q0-17.12-11.3-21.67v-.31q6.28-1.89 8.87-7.7 2.59-5.8 2.59-15.54v-19.31q0-4.71.31-7.61.32-2.91 1.57-5.73h-17.58q-.94 2.66-1.26 5.02-.31 2.35-.31 8.48v20.09q0 7.54-2.43 10.52-2.44 2.99-8.4 2.99h-5.97v-47.1Zm17.27 62.8h6.28q5.18 0 7.77 2.66 2.59 2.67 2.59 8.95v8.48q0 5.97-2.12 8.64t-6.67 2.67h-7.85Zm41.46-62.8 17.9 109.9h23.39l17.9-109.9h-17.27l-3.14 21.82v-.32h-19.63l-3.14-21.5Zm21.19 36.42h15.55l-7.7 54.32h-.31Zm46.31-36.42v109.9h26.37q12.88 0 19.31-6.91 6.44-6.91 6.44-20.26v-55.57q0-13.35-6.44-20.26-6.43-6.9-19.31-6.9Zm17.27 15.7h8.79q4.24 0 6.52 2.51 2.27 2.51 2.27 8.16v57.15q0 5.65-2.27 8.16-2.28 2.52-6.52 2.52h-8.79Zm46.47-15.7v109.9h47.1v-15.7h-29.83v-29.05h23.71v-15.7h-23.71v-33.75h29.83v-15.7Zm56.99 0v109.9h25.59q13.35 0 19.47-6.21 6.12-6.2 6.12-19.07v-6.75q0-17.12-11.3-21.67v-.31q6.28-1.89 8.87-7.7 2.59-5.8 2.59-15.54v-19.31q0-4.71.31-7.61.32-2.91 1.57-5.73h-17.58q-.94 2.66-1.26 5.02-.31 2.35-.31 8.48v20.09q0 7.54-2.43 10.52-2.44 2.99-8.4 2.99h-5.97v-47.1Zm17.27 62.8h6.28q5.18 0 7.77 2.66 2.59 2.67 2.59 8.95v8.48q0 5.97-2.12 8.64t-6.67 2.67h-7.85Z" paint-order="stroke" />
            </g>
            <path fill="transparent" stroke="transparent" d="M470 0h560v298H470z" />
        </g>
    </svg>
);


