export interface UserCoupon {
    couponFixtures: CouponFixture[]
    roundId: number;
    system: string;
}
export interface CouponFixture {
    gameIndex: number;
    "1": boolean;
    "X": boolean;
    "2": boolean;
    isMathematical: boolean;
}

export class CouponInfo{
    public numRows: number;
    public numHalfCover: number;
    public numWholeCover: number;

    constructor(numRows: number, numHalfCover: number, numWholeCover: number) {
        this.numRows = numRows;
        this.numHalfCover = numHalfCover;
        this.numWholeCover = numWholeCover;         
    }
}