import axios from "axios";
import { Round } from "../types/poolx";

export function getPoolXRounds(setPoolXRounds:React.Dispatch<React.SetStateAction<Round[]>>) {

    var config = {
        method: 'get',
        url: 'https://publicdata.live.poolxbet.com/rounds/',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setPoolXRounds(data.rounds);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getFriendlyName(input: string): string {
    if (input.endsWith("EUR")) {
        return input.slice(0, -3); // Removes the "EUR" suffix
    }
    return input; // Returns the input as is if it doesn't end with "EUR"
}